import { type IPQL } from '.'

const signOptionsCollection1 = [
  {
    title: 'contained in',
    value: 'IN'
  },
  {
    title: 'not contained in',
    value: 'NOT_IN'
  },
  {
    title: 'starts with',
    value: 'START_WITH'
  },
  {
    title: 'not starts with',
    value: 'NOT_START_WITH'
  }
]
const hourOfDayCollection = [
  {
    title: 'greater than',
    value: '>'
  },
  {
    title: 'less than',
    value: '<'
  },
  {
    title: 'greater or equal to',
    value: '>='
  },
  {
    title: 'less or equal to',
    value: '<='
  }
]

const weekColletion = [
  {
    title: 'contained in',
    value: 'IN'
  }
]

const advertiserCollection = [
  {
    title: 'contained in',
    value: 'IN'
  },
  {
    title: 'not contained in',
    value: 'NOT_IN'
  }
]

const signEqualOnly = [{
  title: 'equal to',
  value: '='
}]

const fieldOptions = [
  // {
  //   title: 'Advertiser',
  //   value: 'advertiserUuid',
  //   collection: 'advertiser',
  //   valueField: 'advertiser'
  // },
  {
    title: 'Aff Sub 1',
    value: 'aff_sub1',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Aff Sub 2',
    value: 'aff_sub2',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Aff Sub 3',
    value: 'aff_sub3',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Aff Sub 4',
    value: 'aff_sub4',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Aff Sub 5',
    value: 'aff_sub5',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Aff Sub 6',
    value: 'aff_sub6',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Aff Sub 7',
    value: 'aff_sub7',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Aff Sub 8',
    value: 'aff_sub8',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Aff Sub 9',
    value: 'aff_sub9',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Aff Sub 10',
    value: 'aff_sub10',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Aff Sub 11',
    value: 'aff_sub11',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Aff Sub 12',
    value: 'aff_sub12',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Aff Sub 13',
    value: 'aff_sub13',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Aff Sub 14',
    value: 'aff_sub14',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Aff Sub 15',
    value: 'aff_sub15',
    collection: 'literal',
    valueField: 'custom'
  },
  // {
  //   title: 'Affiliate',
  //   value: 'affiliate_id',
  //   collection: 'literal',
  //   valueField: 'custom'
  // },
  {
    title: 'Country',
    value: 'country',
    collection: 'literal',
    valueField: 'country'
  },
  {
    title: 'Day of Week',
    value: 'dayOfWeek',
    collection: 'week',
    valueField: 'week'
  },
  {
    title: 'Email',
    value: 'email',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'First Name',
    value: 'first_name',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Last Name',
    value: 'last_name',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Ip',
    value: 'ip',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Is Test',
    value: 'isTest',
    collection: 'equal',
    valueField: 'test'
  },
  {
    title: 'Hour of Day',
    value: 'hourOfDay',
    collection: 'hourOfDay',
    valueField: 'hour'
  },
  {
    title: 'Lead Source',
    value: 'leadSource',
    collection: 'literal',
    valueField: 'custom'
  },
  // {
  //   title: 'Offer',
  //   value: 'offer_id',
  //   collection: 'literal',
  //   valueField: 'custom'
  // },
  {
    title: 'Password',
    value: 'password',
    collection: 'literal',
    valueField: 'custom'
  },
  {
    title: 'Phone',
    value: 'phone',
    collection: 'literal',
    valueField: 'custom'
  }
  // {
  //   title: 'State',
  //   value: 'fsmState'
  // },
  // {
  //   title: 'Traffic Provider',
  //   value: 'tpUuid'
  // }
]

const weekOptions = [
  {
    title: 'Monday',
    value: 'Monday'
  },
  {
    title: 'Tuesday',
    value: 'Tuesday'
  },
  {
    title: 'Wednesday',
    value: 'Wednesday'
  },
  {
    title: 'Thursday',
    value: 'Thursday'
  },
  {
    title: 'Friday',
    value: 'Friday'
  },
  {
    title: 'Saturday',
    value: 'Saturday'
  },
  {
    title: 'Sunday',
    value: 'Sunday'
  }
]

const testOptions = [
  { title: 'Yes', value: true },
  { title: 'No', value: false }
]

const firstPQL: IPQL = {
  connector: undefined,
  field: '',
  sign: '',
  value: null
}

const newPQL: IPQL = {
  connector: 'AND',
  field: '',
  sign: '',
  value: null
}

export {
  signOptionsCollection1,
  hourOfDayCollection,
  weekColletion,
  advertiserCollection,
  signEqualOnly,
  fieldOptions,
  weekOptions,
  testOptions,
  firstPQL,
  newPQL
}
