import React from 'react'

import { useForm, type SubmitHandler } from 'react-hook-form'
import AddPopupLayout, {
  type Inputs,
  type AddPopupProps as AddPopupMuiProps
} from '@/designSystem/Pages/LeadConversions/AdvertiserRevenue/Popups/AddOrEdit'
import { useEditRevenueMutation } from '@/store/advertiserRevenue'
import { type IAdveriserRevenue } from './AdvertiserRevenueTypes'

type AddPopupProps = Pick<AddPopupMuiProps, 'handleClose' | 'nodeParentId'> & { revenue?: IAdveriserRevenue }

export default function AddPopup ({ handleClose, nodeParentId, revenue }: AddPopupProps) {
  const form = useForm<any>({ // TODO
    defaultValues: { ...revenue, revenueValue: revenue?.revenue }
  })

  const [editRevenue] = useEditRevenueMutation()

  const onSubmit: SubmitHandler<Inputs> = async ({
    revenueValue
  }) => {
    if (revenue?.id) {
      await editRevenue({
        id: revenue.id,
        revenue: Number(revenueValue)
      })
    }

    handleClose()
  }

  return (
    <AddPopupLayout
      nodeParentId={nodeParentId}
      form={form}
      onSubmit={onSubmit}
      handleClose={handleClose}
      title='Edit revenue'
      subtitle='Change value of revenue'
    />
  )
}
