import React from 'react'
import JsonView from '@uiw/react-json-view'
import { timeZones } from '@/constants'
import ActionButtons from '@/designSystem/ActionButtons'
import Autocomplete, * as AutocompleteDS from '@/designSystem/Autocomplete'
import DateTimePicker from '@/designSystem/DateTimePicker'
import FlexBox from '@/designSystem/FlexBox'
import Popup from '@/designSystem/Popup'
import composeSx from '@/designSystem/TextField/v2'
import moment from 'moment-timezone'
import DownloadIcon from '@mui/icons-material/Download'

import {
  selectPullLeadStateSettings,
  useLazyManualLeadPullQuery,
  useLazyTestLeadPullQuery
} from '@/store/advertisers'
import IconButton from '@/designSystem/IconButton'
import { Box, styled, type SxProps, Typography, useTheme } from '@mui/material'
import { PullLeadActionType, PullLeadSettingsType } from '@/enums/advertiser.enum'
import { pullSettingsTypeOptions } from '@/utils/advertisers'
import IOSSwitch from '@/designSystem/IOSSwitch'
import { useSelector } from 'react-redux'

interface ITestPullModal {
  open: boolean
  handleClose: () => void
  advertiserId: string
  pullLeadActionType: PullLeadActionType
}

export const TestPullModal = ({ open, handleClose, advertiserId, pullLeadActionType }: ITestPullModal) => {
  const theme = useTheme()
  const pullLeadStateSettings = useSelector(selectPullLeadStateSettings)
  const { palette } = theme
  const [timeZone, setTimeZone] = React.useState(timeZones[0]?.value)
  const [fromTime, setFromTime] = React.useState<Date | null>(null)

  const [toTime, setToTime] = React.useState<Date | null>(null)
  const [isFetching, setIsFetching] = React.useState<boolean>(false)
  const [settingsType, setSettingsType] = React.useState<PullLeadSettingsType>(PullLeadSettingsType.PULL_LEAD_STATUSES)
  const [pullResponseData, setPullResponseData] = React.useState<any | null>(null)
  const [testLeadPullQuery] = useLazyTestLeadPullQuery()
  const [manualLeadPullQuery] = useLazyManualLeadPullQuery()
  const [showRequestAndResponse, setShowRequestAndResponse] = React.useState<boolean>(false)
  const { pullLeadsData, responseAndRequestData, error = false } = pullResponseData ?? {}
  const textFieldsSx = composeSx(theme)
  const datePickerSx: SxProps = {
    backgroundColor: palette.inputText.main,
    borderRadius: '16px',
    height: '38px'
  }
  const handleSubmit = async () => {
    setPullResponseData(null)
    setIsFetching(true)
    setShowRequestAndResponse(false)
    const format = 'YYYY-MM-DD HH:mm:ss'
    const fromTimeString = moment(fromTime).format(format)
    const fromTimeInTimezone = moment.tz(fromTimeString, format, timeZone).toDate()

    const toTimeString = moment(toTime).format(format)
    const toTimeInTimezone = moment.tz(toTimeString, format, timeZone).toDate()

    const requestData = {
      advertiserId,
      fromTime: fromTimeInTimezone,
      toTime: toTimeInTimezone,
      settingsType
    }
    let newPullResponseData: Record<string, unknown> = {}

    if (pullLeadActionType === PullLeadActionType.TEST_PULL) {
      const { data } = await testLeadPullQuery(requestData)
      newPullResponseData = {
        pullLeadsData: { matchingLeads: data?.matchingLeads },
        responseAndRequestData: { requestData: data?.requestData, responseData: data?.responseData }
      }
      if (data?.error) {
        newPullResponseData = {
          error: true,
          responseAndRequestData: { errorData: data?.responseData, requestData: data?.requestData }
        }
      }

      setPullResponseData(newPullResponseData)
    } else if (pullLeadActionType === PullLeadActionType.MANUAL_PULL) {
      const { data, error } = await manualLeadPullQuery(requestData)
      newPullResponseData = {
        pullLeadsData: { processedLeads: data?.processedLeads, advertiserLeads: data?.advertiserLeads },
        responseAndRequestData: { logs: data?.pullLeadLogs }
      }
      if (error) {
        newPullResponseData = { error: true, responseAndRequestData: { errorData: (error as any)?.data } }
      }
      setPullResponseData(newPullResponseData)
    }
    setIsFetching(false)
  }
  const handleChangeTimezone = (e: any) => {
    setTimeZone(e?.value)
  }

  const handleCloseTestModal = () => {
    setPullResponseData(null)
    handleClose()
  }
  const onCopy = (text: string) => text && navigator?.clipboard?.writeText(text)

  const handleSettingTypeChange = (e: { value: string, title: string } | null) => {
    if (e?.value) {
      setSettingsType(e.value as PullLeadSettingsType)
    }
  }
  const downloadJsonFile = (data: object, filename: string) => {
    const jsonString = JSON.stringify(data, null, 2) // Converts the data to a JSON string
    const blob = new Blob([jsonString], { type: 'application/json' })
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.download = filename

    link.click()

    URL.revokeObjectURL(url)
  }

  return (
    <Popup
      open={open}
      handleClose={handleCloseTestModal}
      title={pullLeadActionType === PullLeadActionType.TEST_PULL ? 'Test Pulling' : 'Manual Pulling'}
    >
      <FlexBox column justifyContent={'space-between'} gap={5}>
        <Autocomplete
          plaseholder='Choose Timezone'
          defaultValue={timeZones[0]}
          onChange={(e: any) => handleChangeTimezone(e)}
          textFieldProps={{
            root: {
              sx: textFieldsSx
            }
          }}
          options={timeZones}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        />
        {pullResponseData &&
         <Box>
           {!error &&
            <Box>
              <Typography variant='body2'>
                {pullLeadActionType === PullLeadActionType.TEST_PULL ? 'Matching leads' : 'Processed leads'}
              </Typography>

              <Box
               maxHeight={'520px'}
               marginBottom={'20px'}
               marginTop={'20px'}
               overflow={'auto'}
              >
                <JsonView value={pullLeadsData} displayDataTypes={false} displayObjectSize={false}
                          onCopied={(text, value) => onCopy(text)} shortenTextAfterLength={10000}
                          enableClipboard={false}/>
              </Box>
            </Box>
           }
           {!error &&
            <FlexBox justifyContent={'space-between'}>
            <IOSSwitch
             formControlLabel={{
               label: 'Show request and response',
               labelPlacement: 'end',
               onChange: (e: any) => {
                 setShowRequestAndResponse(!!e?.target?.checked)
               },
               checked: showRequestAndResponse,
               sx: {
                 margin: 0,
                 '& .MuiTypography-root': {
                   marginRight: '12px',
                   marginLeft: '10px'
                 }
               }
             }}
            />
              <IconButton
               onClick={() => downloadJsonFile(responseAndRequestData, 'test-pulling-data.json')}
               sx={{ height: 35, width: 35, marginBottom: '10px' }}
              >
                <DownloadIcon sx={{ color: '#fff', fontSize: 25 }}/>
              </IconButton>
            </FlexBox>
           }

           {(error || showRequestAndResponse) &&
             <Box
             maxHeight={'520px'}
             marginBottom={'20px'}
             marginTop={'20px'}
             overflow={'auto'}
             >
             <JsonView value={responseAndRequestData} displayDataTypes={false} displayObjectSize={false}
             onCopied={(text, value) => onCopy(text)} shortenTextAfterLength={10000}
             enableClipboard={true}/>

             </Box>
           }
         </Box>
        }

        <FlexBox column gap={7} >
          <Box
            display={'flex'}
            alignItems={'center'}
            marginTop={'20px'}
            gap={'20px'}
          >
            <CustomizedDatePickers sx={datePickerSx} label='Select date from'
              onAccept={(e: any) => { setFromTime(e) }} value={fromTime}
                                   customTimezone={'UTC'}
            />
            -
            <CustomizedDatePickers sx={datePickerSx} label='Select date to'
              onAccept={(e: any) => { setToTime(e) }}
                                   customTimezone={'UTC'}
            />

          </Box>
          {pullLeadStateSettings.length > 0 &&
           <AutocompleteDS.default
            sx={{ '& .MuiOutlinedInput-root': { padding: '0px 4px 7.5px 5px' } }}
            plaseholder='Settings Type'
            onChange={(e: { value: string, title: string } | null) => {
              handleSettingTypeChange(e)
            }}
            options={pullSettingsTypeOptions}
            value={pullSettingsTypeOptions.find((i) => i.value === settingsType)}
           />
          }
          <ActionButtons submitText='Submit test' disabled={ !(timeZone && fromTime && toTime && !isFetching)} onCancel={handleClose} onSubmit={handleSubmit} />
        </FlexBox>
      </FlexBox>
    </Popup>
  )
}

const CustomizedDatePickers = styled(DateTimePicker)(`  
  .MuiInputBase-root{
    background: #FFFFFF;
    border-radius: 16px;
    padding: 10px 16px;
  }
  input{
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #393E51;
  }
  label{
    color: #6B6B6B;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: -9px;
    &.Mui-focused{
      margin-top: -4px;
    }
  }
  `)
