import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { type IRotationControl } from '@/components/Pages/TrafficDistribution/RotationControl/RotationControlTypes'
import { type SentLeadStatus } from '@/enums/sentLeads.enum'
export interface ExtraSettings {
  key: string
  value: string
}

export interface TimeReset {
  id: string
  hour: number
}

export type RotationControlWithExtraSettings = IRotationControl & {
  other?: ExtraSettings[]
}

export interface FiltersRotationControl {
  status?: any
  country?: string[]
  advertiserId?: string[]
}

interface GetRotationsControlArgs {
  filters?: FiltersRotationControl
}
interface GetRotationControlArgs {
  id: string
  include?: string[]
  sentLeadsFilter?: {
    status: SentLeadStatus.REJECTED
    take: number
  }
}

type CreateEditRotationArgs = Partial<IRotationControl>

export const rotationControlApi = createApi({
  reducerPath: 'rotationControlApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/rotations`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['RotationControl', 'TimeReset'],
  endpoints: (builder) => ({
    getRotations: builder.query<IRotationControl[], GetRotationsControlArgs>({
      query: ({ filters }) => ({
        url: '/get-rotations',
        method: 'POST',
        body: {
          filter: { ...filters, ...filters?.status ? { status: filters?.status ? 'ACTIVE' : 'INACTIVE' } : {} }
        }
      }),
      providesTags: [{ type: 'RotationControl', id: 'LIST' }]
    }),
    getRotation: builder.query<RotationControlWithExtraSettings, GetRotationControlArgs>({
      query: ({ id, include, sentLeadsFilter }) => {
        const params: Record<string, unknown> = { include }
        if (sentLeadsFilter) {
          params['sentLeadsFilter[status]'] = sentLeadsFilter.status
          params['sentLeadsFilter[take]'] = sentLeadsFilter.take
        }
        return {
          url: `/${id}`,
          method: 'GET',
          params
        }
      },
      providesTags: [{ type: 'RotationControl', id: 'LIST' }]
    }),
    createRotation: builder.mutation<
    IRotationControl,
    CreateEditRotationArgs
    >({
      query: (query) => ({
        url: '',
        method: 'POST',
        body: query
      }),
      invalidatesTags: [{ type: 'RotationControl', id: 'LIST' }]
    }),
    cloneRotation: builder.mutation<
    IRotationControl,
    { id: string }
    >({
      query: (query) => ({
        url: '/cloneRotation',
        method: 'POST',
        body: {
          rotationid: query.id
        }
      }),
      invalidatesTags: [{ type: 'RotationControl', id: 'LIST' }]
    }),
    editRotations: builder.mutation<
    IRotationControl,
    Array<Partial<CreateEditRotationArgs> & { id: string }>
    >({
      query: (query) => ({
        url: '',
        method: 'POST',
        body: query
      }),
      invalidatesTags: [{ type: 'RotationControl', id: 'LIST' }]
    }),
    editRotation: builder.mutation<
    IRotationControl,
    Partial<CreateEditRotationArgs> & { id: string }
    >({
      query: ({ id, ...rest }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: rest
      }),
      invalidatesTags: [{ type: 'RotationControl', id: 'LIST' }]
    }),
    batchUpdatePriority: builder.mutation<Record<'data', IRotationControl[]>, Array<Record<'id' | 'priority', string | number>>>({
      query: (data) => ({
        url: '/update-rotations',
        method: 'POST',
        body: {
          items: data
        }
      }),
      invalidatesTags: [{ type: 'RotationControl', id: 'LIST' }]
    }),
    deleteRotation: builder.mutation<IRotationControl, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'RotationControl', id: 'LIST' }]
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetRotationsQuery,
  useGetRotationQuery,
  useCreateRotationMutation,
  useCloneRotationMutation,
  useEditRotationMutation,
  useBatchUpdatePriorityMutation,
  useDeleteRotationMutation
} = rotationControlApi
