import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import {
  useGetLeadsQuery,
  setLeadsTotalCount,
  selectLeads,
  setLeads,
  selectLeadsTotalCount,
  type Lead
} from '@/store/leads'
import { selectLeadsQueryParams } from '@/store/queryParams'
import { Typography, useTheme } from '@mui/material'
import TableLayout from '@/designSystem/Table'
import elementLeadsMap from './ElementLeadsMap'
import LeadsFilterBar from './Filters/LeadsFilterBar'
import { EmptyTableLeads } from './EmptyTableLeads'
import LeadsPagination from './LeadsPagination'

import FlexBox from '@/designSystem/FlexBox'
import LeadsActions from './LeadsActions'
import { ModalEventInfo } from '@/designSystem/ModalMessagesLogs'
import UpdateLeadStateModal from '@/components/Pages/TrafficData/Leads/LeadInformationPage/UpdateLeadStateModal'
import { LeadState } from '@/enums/leads.enum'
import { ToastContainer } from 'react-toastify'
import QuickSearchFilter from '@/components/Pages/TrafficData/Leads/Filters/QuickSearchFilterInput'

interface HeaderProps {
  header: string
  setChecked: (value: any) => void
  isChecked: boolean
}

const Header = ({ header, setChecked, isChecked }: HeaderProps) => {
  const onClick = () => {
    setChecked(!isChecked)
  }

  if (header.startsWith('::')) {
    return <input
      type='checkbox'
      style={{
        marginLeft: '18px',
        width: '24px',
        height: '24px'
      }}
      checked={isChecked}
      onClick={onClick}
    />
  }
  return (
    <Typography
      variant='body1'
      color="text1"
      width="max-content"
      sx={{
        display: 'flex'
      }}
    >
      {header}
    </Typography>
  )
}

export default function LeadsTable () {
  const dispatch = useDispatch()
  const [isHeaderChecked, setHeaderChecked] = useState(false)
  const [openUpdateLeadStateModal, setOpenUpdateLeadStateModal] = useState(false)
  const [checkedIds, setCheckedIds] = useState<string[]>([])

  const { limit = 20, headers, filters, page } = useSelector(selectLeadsQueryParams)

  const { data } = useGetLeadsQuery({
    limit,
    filters,
    page
  })
  const currentPageIds = useMemo(() => {
    return data?.leads.map((i) => i.id) ?? []
  }, [data?.leads])

  const { spacing } = useTheme()
  const [modalLogs, setModalLogs] = React.useState<any>(null)
  const leadsState: Lead[] = useSelector(selectLeads)
  const [stateLeads, setStateLeads] = React.useState<any>(leadsState ?? [])
  const headersWithExtraFields = ['::indicatorLeads']?.concat(headers)

  useEffect(() => {
    const allChecked = currentPageIds.length > 0 && currentPageIds.every(id => checkedIds.includes(id))
    if (isHeaderChecked !== allChecked) {
      setHeaderChecked(allChecked)
    }
  }, [currentPageIds, checkedIds])

  const handleHeaderCheck = (checked: boolean) => {
    setHeaderChecked(checked)
    if (checked) {
      setCheckedIds(prevCheckedIds => {
        const updatedIds = [...prevCheckedIds, ...currentPageIds]
        return Array.from(new Set(updatedIds))
      })
    } else {
      setCheckedIds((prevCheckedIds) => prevCheckedIds.filter(id => !currentPageIds.includes(id)))
    }
  }
  const headerNodes: React.ReactNode[] =
    headersWithExtraFields?.map((header) => <Header isChecked={isHeaderChecked} setChecked={handleHeaderCheck} key={header} header={header} />)
  const handleCheck = (id: string) => {
    if (checkedIds.includes(id)) {
      setCheckedIds(checkedIds.filter((i) => i !== id))
    } else {
      setCheckedIds([...checkedIds, id])
    }
  }

  const clearIds = () => {
    setCheckedIds([])
  }

  const handleOpenLead = (id: string) => {
    window.open(`/traffic-data/leads/${id}`, '_blank')
  }
  // const onRowClick = (row: React.ReactNode[]) => {
  //   console.log('row', row)
  // }
  const handleExpandLead = (id: string) => {
    const newLeads: any = []
    stateLeads.forEach((lead: Lead) => {
      if (lead?.id === id) {
        newLeads.push({ ...lead, isOpenExpand: true })
        lead?.sentLeads?.forEach((item: any) =>
          newLeads.push({
            ...item,
            isExpand: lead?.id
          }))
      } else {
        newLeads.push(lead)
      }
    })
    setStateLeads(newLeads)
  }

  const handleBackExpandLead = (id: string) => {
    const newLeads = stateLeads.filter((lead: any) => lead?.isExpand !== id).map((lead: any) => {
      if (lead?.id === id) {
        return {
          ...lead,
          isOpenExpand: false
        }
      }
      return lead
    })
    setStateLeads(newLeads)
  }

  const rowNodes: any = stateLeads?.map((row: any) => (
    headersWithExtraFields?.map((header) => elementLeadsMap(header, row, checkedIds.includes(row.id), handleCheck,
      handleOpenLead, handleExpandLead, handleBackExpandLead, setModalLogs))
  ))
  const selectedLeads = (data?.leads ?? []).filter((item: { id: string, state: LeadState }) => checkedIds.includes(item.id))
  useEffect(() => {
    if (data?.leads) {
      data && dispatch(setLeads(data?.leads))
      setStateLeads(data?.leads)
      dispatch(setLeadsTotalCount(data?.totalCount ?? 0))
    } else {
      setStateLeads([])
    }
  }, [data])
  return (
  <Box
    display="flex"
    flexDirection="column"
    gap={spacing(6)}
  >

    <FlexBox justifyContent={'space-between'} >
      <QuickSearchFilter type={'leads'}/>
      <LeadsPagination />
       {(checkedIds.length > 0 && selectedLeads.every(item => item.state === LeadState.PUSHED_LEAD)) && <LeadsActions setOpenUpdateLeadStateModal={setOpenUpdateLeadStateModal} ids={checkedIds} clearIds={clearIds} />}

    </FlexBox>

    <LeadsFilterBar />
    <TableLayout
      headers={headerNodes}
      rows={rowNodes}
      // onRowClick={onRowClick}
      sx={{
        '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-of-type': {
          width: 0
        }
      }}
    />
    {(rowNodes?.length === 0) && <EmptyTableLeads />}
    {modalLogs && <ModalEventInfo
      open={!!modalLogs}
      handleClose={() => setModalLogs(null)}
      logs={modalLogs}
    />}
    <ToastContainer />
     {openUpdateLeadStateModal && (
      <UpdateLeadStateModal leadIds={checkedIds} handleClose={() => {
        setOpenUpdateLeadStateModal(false)
        setOpenUpdateLeadStateModal(false)
      } } />
     )}
  </Box>
  )
}
