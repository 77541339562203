import React, { useEffect } from 'react'
import ActionButtons from '@/designSystem/ActionButtons'
import Popup from '@/designSystem/Popup'
import { Box, Typography, useTheme } from '@mui/material'
import { useEditAutoLoginMutation, useGetAllSettingsQuery, useGetAutologinDomainsQuery } from '@/store/settings'
import Autocomplete from '@/designSystem/Pages/Advertisers/Autocomplete'

interface IPopup {
  open: boolean
  handleClose: () => void
}

export const AutoLoginDomainPopup = ({ open, handleClose }: IPopup) => {
  const theme = useTheme()
  const [submitAutoLogin] = useEditAutoLoginMutation()
  const getAutologinDomainsRefetch = useGetAutologinDomainsQuery({}).refetch
  const domainsList = useGetAutologinDomainsQuery({}).data ?? []
  const domainsListOptions = domainsList.map((item: any) => {
    return { value: item.id, title: item.domain, active: item.active }
  })
  const currentDomain = domainsListOptions.find((item: any) => item.active)
  const [autoLogin, setAutoLogin] = React.useState<string | null>(currentDomain?.title || null)
  const [error, setError] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)

  useEffect(() => {
    void getAutologinDomainsRefetch()
  }, [open])

  const handleCancelled = () => {
    handleClose()
    setAutoLogin(currentDomain?.title ?? null)
    setError('')
  }

  const onSubmit = async () => {
    if (!autoLogin) {
      setError('Required field')
      return
    }
    setLoading(true)
    await submitAutoLogin({
      autoLoginDomain: `https://${autoLogin}`
    }).then((res: any) => {
      setLoading(false)
      if (res?.error?.data?.message) {
        setError(res.error.data.message)
      } else {
        handleCancelled()
      }
    }).catch(() => {
      setLoading(false)
    })
  }
  const changeActiveDomain = (e: { value: string, title: string } | null) => {
    setAutoLogin(e?.title ?? null)
  }

  return (
    <Popup
      open={open}
      title="Edit Auto Login"
      handleClose={handleCancelled}
      overflow='auto'
      maxWidth='600px'
      padding='40px'
    >
      <Box
        height={'290px'}
        display='flex'
        flexDirection='column'
        justifyContent={'space-between'}>

        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
        >
          <Typography
            sx={{
              marginBottom: theme.spacing(2),
              marginTop: theme.spacing(8)
            }}
          >
            Choose active domain for autologin server
          </Typography>
          <Autocomplete
            options={domainsListOptions}
            onChange={changeActiveDomain}
            defaultValue={currentDomain}
            placeholder="Choose active domain for autologin"
            sx={{ width: '100%' }}
          />

          {error && <Typography sx={{
            color: theme.palette.error.main,
            marginTop: '4px',
            fontSize: '12px'
          }}>{error}</Typography>}

        </Box>
        <ActionButtons
          sx={{ paddingTop: '54px' }}
          onCancel={handleCancelled}
          onSubmit={onSubmit}
          disabled={currentDomain?.title === autoLogin || loading}
          submitText="Submit"
          cancelText='Cancel'
        />
      </Box>
    </Popup>
  )
}
