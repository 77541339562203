import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import { format } from 'date-fns'
import { useGetInjectionsQuery, setInjectMenu } from '@/store/injections'
import { setInjectQueryParams, selectInjectQueryParams, setAutoInjectQueryParams } from '@/store/queryParams'
import type { Option } from '@/designSystem/Autocomplete/types'
import setProperty from '@/utils/setProperty'
import FilterIcon from '@/designSystem/Pages/Advertisers/Icons/Filter'
import SettingsIcon from '@/assets/icons/Settings'
import { Box, useTheme } from '@mui/material'
import Button from '@/designSystem/Button'
import IconButton from '@/designSystem/IconButton'
import Autocomplete from '@/designSystem/Pages/Advertisers/Autocomplete'
import InjectFiltersPopup from './Filters/InjectFiltersPopup'
import isObjectNotEmpty from '@/utils/isObjectNotEmpty'
import InjectionsSettingsPopup from '@/components/Injections/Filters/InjectionsSettingsPopup'
import { getLastWeek } from '@/components/Dashboard/utils'
import { getPeriodFilterOptions } from '@/utils/filters'

export default function RightActionButtons () {
  const theme = useTheme()
  const [isOpenFilterPopup, setIsOpenFilterPopup] = useState(false)
  const [isSettingsOpen, setIsSettingOpen] = useState(false)

  const [name, setName] = useState<string | null>(null)
  const [debouncedName] = useDebounce(name, 450)

  const dispatch = useDispatch()
  const queryParams = useSelector(selectInjectQueryParams)

  const isFilter = queryParams?.filters && isObjectNotEmpty(queryParams?.filters)

  const onChangeDate = (option: Option<string> | null) => {
    return option?.value && dispatch(setInjectQueryParams({
      ...queryParams,
      page: 1,
      filters: setProperty({ ...queryParams?.filters }, 'createdAt', {
        from: new Date(option?.value).toISOString(),
        to: option?.title === 'Last week' ? getLastWeek().to : new Date().toISOString()
      })
    }))
  }
  const onInputChangeDate = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value
    if (!value) {
      dispatch(setInjectQueryParams({
        ...queryParams,
        page: 1,
        filters: setProperty({ ...queryParams?.filters }, 'date')
      }))
    }
  }

  const nameOptions: Array<Option<string>> | undefined =
    useGetInjectionsQuery({ limit: 6, filters: debouncedName ? { name: debouncedName } : undefined })
      .data?.injection?.map(({ name }) => ({ title: name, value: name }))
  const onInputChangeName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value
    setName(value ?? null)
    if (!value) {
      dispatch(setInjectQueryParams({
        ...queryParams,
        filters: setProperty({ ...queryParams?.filters }, 'name')
      }))
    } else {
      dispatch(setInjectQueryParams({
        ...queryParams,
        filters: setProperty({ ...queryParams?.filters }, 'name', debouncedName)
      }))
    }
  }
  const onChangeName = (value: Option<string> | null) => {
    setName(value?.value ?? null)
    return value && dispatch(
      setInjectQueryParams({
        ...queryParams,
        page: 1,
        filters: setProperty({ ...queryParams?.filters }, 'name', value?.value)
      })
    )
  }

  useEffect(() => {
    if (!queryParams?.filters?.name) {
      setName(null)
    }
  }, [queryParams?.filters?.name, queryParams?.filters?.createdAt])

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={theme.spacing(4)}
      >
        {isFilter && <Button
          onClick={() => {
            dispatch(setInjectQueryParams({ ...queryParams, page: 1, filters: undefined }))
            dispatch(setInjectMenu({ name: '', id: '' }))
          }}
          text='Reset filter'
          variant='contained'
          sx={{
            backgroundColor: theme.palette.red2.main,
            padding: `${theme.spacing(2)} ${theme.spacing(6)}`
          }}
        />}
        <IconButton onClick={() => setIsOpenFilterPopup(true)}>
          <FilterIcon />
        </IconButton>
        <Box
          display="flex"
          alignItems="center"
          gap={theme.spacing(3)}
        >
          <Autocomplete
            onInputChange={onInputChangeName}
            onChange={onChangeName}
            value={{ value: name ?? '', title: name ?? '' }}
            clearIcon=''
            options={nameOptions ?? []}
            placeholder='Name'
            leftShift
          />
          <Autocomplete
            onInputChange={onInputChangeDate}
            onChange={onChangeDate}
            options={getPeriodFilterOptions()}
            clearIcon=''
            placeholder='Date'
            leftShift
          />
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => setIsSettingOpen(true)}
          >
            <SettingsIcon />
          </IconButton>
        </Box>
      </Box>
      {isSettingsOpen && (<InjectionsSettingsPopup handleClose={() => setIsSettingOpen(false)} />)}
      {isOpenFilterPopup && (
        <InjectFiltersPopup handleClose={() => setIsOpenFilterPopup(false)} />
      )}
    </>
  )
}
