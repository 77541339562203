export enum PullLeadSettingsType {
  PULL_LEAD_STATUSES = 'PULL_LEAD_STATUSES',
  PULL_LEAD_STATES = 'PULL_LEAD_STATES'
}

export enum PullLeadActionType {
  TEST_PULL = 'TEST_PULL',
  MANUAL_PULL = 'MANUAL_PULL'
}
export enum StateCheckTypeEnum {
  STATE_FIELD_VALUE = 'state_field_value',
  LEAD_ID_AVAILABILITY = 'lead_id_availability',
  FIELD_AVAILABILITY = 'field_availability'
}
