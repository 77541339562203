/* eslint-disable react/prop-types */
import React from 'react'

import { Box, Typography } from '@mui/material'

import LeadTableWrapper from './LeadTableWrapper'
import { type Lead } from '@/store/leads'
import { BooleanField, CountryField, DateTimeField, TextField } from '@/designSystem/Table/Fields'
import { StateField } from '../../ElementLeadsMap'
import { ModalEventInfo } from '@/designSystem/ModalMessagesLogs'
import { type PostbackSent } from '@/store/postBacksSent'
import { ReactComponent as ThreeDots } from '@/assets/icons/three-dots.svg'
import { ReactComponent as EyeIcon } from '@/assets/icons/eye.svg'
import { useNavigate } from 'react-router-dom'
import { LeadState } from '@/enums/leads.enum'

interface ILead {
  dataLead: Lead | null
  onClickReload: () => void
  postBackSentData: PostbackSent[]
  filteredAndSortedLogs: any
}

export const LimitTextField = ({ text }: { text: string | undefined }) => {
  return (
    <Box>
      {(text && text?.length > 10) ? <TextField text={text} limitation={80} /> : text}
    </Box>
  )
}

const BoxWrapper = ({ children }: any) => {
  return (
    <Box
      sx={{ height: '67px', display: 'table-cell', verticalAlign: 'middle' }}
    >
      {children}
    </Box>
  )
}

const LitleSell = ({ children, width }: any) => {
  return (
    <BoxWrapper>
      <Typography
        display="flex"
        variant="body1"
        color={'#393E51'}
        width={width}
        textOverflow={'ellipsis'}
        overflow={'hidden'}
        whiteSpace={'nowrap'}
      >
        {children}
      </Typography>
    </BoxWrapper>
  )
}

const MenuLeadTable = ({ dataLead, onClickReload, postBackSentData, filteredAndSortedLogs }: ILead) => {
  const navigate = useNavigate()
  const [modalLogs, setModalLogs] = React.useState<any>(null)

  const handleSetModalLogs = (logs: any) => {
    setModalLogs(logs)
  }

  const handleGoToAdvertiserPage = () => {
    dataLead?.advertiser?.name && navigate(`/traffic-distribution/advertisers/${dataLead?.advertiserId}`)
  }

  const rowsLeadsMain = [
    {
      id:
        <TextField text={dataLead?.id} limitation={80} />,
      country:
        <CountryField countryCode={dataLead?.country ?? ''} />,
      ip:
        <LitleSell width="max-content">
          {dataLead?.ip}
        </LitleSell>,
      state:
        <StateField state={dataLead?.state ?? LeadState.PUSHED_LEAD} />,
      isTest:
        <BooleanField isSomething={dataLead?.isTest ?? false} />
    }]

  const rowsLeadsAdvertiserData = [
    {
      advertiser:
        <BoxWrapper>
          <Box
            display={'flex'}
            alignItems={'start'}
            flexDirection={'column'}
            gap={'4px'}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '4px',
                color: dataLead?.advertiser?.name ? '#4141D5' : '#393E51',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                textTransform: 'capitalize',
                cursor: 'pointer'
              }}
              onClick={handleGoToAdvertiserPage}>
              {dataLead?.advertiser?.name && <EyeIcon />}
              {dataLead?.advertiserId ? dataLead?.advertiser?.name ?? '****' : dataLead?.advertiser?.name ?? ''}
            </Box>
            <LimitTextField text={dataLead?.advertiserId} />
          </Box>
        </BoxWrapper>,
      crmSaleStatus:
        <LitleSell width="max-content">
          {dataLead?.crmSaleStatus ?? ''}
        </LitleSell>,
      isAutologinSuccess:
        <BooleanField isSomething={dataLead?.isAutologinSuccess ?? false} />,
      externalStatus:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.externalStatus ?? ''} />
        </LitleSell>,
      externalId:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.externalId ?? ''} />
        </LitleSell>
    }]

  const rowsLeadsAdditional = [
    {
      createdAt:
        <LitleSell width="190px">
          <DateTimeField date={dataLead?.createdAt && new Date(dataLead?.createdAt)} />
        </LitleSell>,
      stateUpdatedAt:
        <LitleSell width="190px">
          <DateTimeField date={dataLead?.stateUpdatedAt && new Date(dataLead?.stateUpdatedAt)} />
        </LitleSell>
    }]

  const rowsLeadsProfile = [
    {
      email:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.email} />
        </LitleSell>,
      firstName:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.firstName ?? '*****'} />
        </LitleSell>,
      lastName:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.lastName ?? '*****'} />
        </LitleSell>,
      phone:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.phone ?? '*****'} />
        </LitleSell>,
      affSub:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.affSub1 ?? 'Empty'} />
        </LitleSell>,
      affSub2:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.affSub2 ?? 'Empty'} />
        </LitleSell>,
      affSub3:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.affSub3 ?? 'Empty'} />
        </LitleSell>,
      affSub4:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.affSub4 ?? 'Empty'} />
        </LitleSell>,
      affSub5:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.affSub5 ?? 'Empty'} />
        </LitleSell>,
      affSub6:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.affSub6 ?? 'Empty'} />
        </LitleSell>,
      affSub7:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.affSub7 ?? 'Empty'} />
        </LitleSell>,
      affSub8:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.affSub8 ?? 'Empty'} />
        </LitleSell>,
      affSub9:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.affSub9 ?? 'Empty'} />
        </LitleSell>,
      affSub10:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.affSub10 ?? 'Empty'} />
        </LitleSell>,
      affSub11:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.affSub11 ?? 'Empty'} />
        </LitleSell>,
      affSub12:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.affSub12 ?? 'Empty'} />
        </LitleSell>,
      affSub13:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.affSub13 ?? 'Empty'} />
        </LitleSell>,
      affSub14:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.affSub14 ?? 'Empty'} />
        </LitleSell>,
      affSub15:
        <LitleSell width="max-content">
          <LimitTextField text={dataLead?.affSub15 ?? 'Empty'} />
        </LitleSell>
    }
  ]

  const rowsExecutedPostbacks = postBackSentData?.map((row: any) => {
    const getUrl = () => {
      if (row?.requestMethod === 'GET') {
        const urlWithParams = new URL(row?.url)
        Object.entries(row?.postbackSentData?.data).forEach(([key, value]: any) => {
          urlWithParams?.searchParams.append(key, value)
        })
        return urlWithParams?.toString()
      } else {
        return row?.url
      }
    }

    return {
      id:
        <TextField text={row?.id} limitation={1} />,
      status:
        <TextField text={row?.status} />,
      content:
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '300px',
            gap: '4px'
          }}
        >
          <Box
            style={{
              width: '250px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}
          >
            {row?.url}
          </Box>
          {row?.url && <ThreeDots
            cursor={'pointer'}
            onClick={() => handleSetModalLogs(getUrl())}
          />}
        </Box >,
      method:
        <TextField text={row?.requestMethod} />,
      date:
        <DateTimeField date={new Date(row?.createdAt)} />
    }
  })

  return (
    <>
      <LeadTableWrapper
        rowsLeadsMain={rowsLeadsMain}
        rowsLeadsAdvertiserData={rowsLeadsAdvertiserData}
        rowsLeadsAdditional={rowsLeadsAdditional}
        rowsLeadsProfile={rowsLeadsProfile}
        onClickReload={onClickReload}
        dataLead={dataLead}
        executedPostbacks={rowsExecutedPostbacks}
        filteredAndSortedLogs={filteredAndSortedLogs}
        handleSetModalLogs = {handleSetModalLogs}
      />
      {modalLogs && <ModalEventInfo
        open={!!modalLogs}
        handleClose={() => setModalLogs(null)}
        logs={modalLogs}
      />}
    </>
  )
}

export default MenuLeadTable
