import React from 'react'
import { Box } from '@mui/material'

import PageContentLayout from '@/designSystem/PageContentLayout'
import LeftActionButtons from '@/components/Conversions/LeftActionButtons'
import RightActionButtons from '@/components/Conversions/RightActionButtons'
import ConversionsTable from '@/components/Conversions/ConversionsTable'

export default function ConversionsPage () {
  return (
    <PageContentLayout
      title={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box sx={{
            color: '#6B6B6B',
            fontSize: '28px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal'
          }}
          >Conversions:
          </Box>
        </Box>
      }

      leftActionButtons={<LeftActionButtons />}
      rightActionButtons={<RightActionButtons />}
    >
     <ConversionsTable />
    </PageContentLayout>
  )
}
