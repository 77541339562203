import React from 'react'
import { BooleanField, CountryField, DateTimeField, Indicator, TextField } from '@/designSystem/Table/Fields'
import { type Hold, holdsHeaders } from '@/store/hold'
import { Box, Typography, useTheme } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { CheckedIdType } from '@/components/Pages/TrafficData/Hold/Table'
import { LeadState } from '@/enums/leads.enum'

const checkIsPushed = (state: Hold['state']) => state === LeadState.PUSHED_LEAD

const fieldToElementMap = (header: string, row: Hold, isChecked: boolean, handleCheck: (id: string, idType: CheckedIdType) => void, isExpanded: boolean | undefined, handleExpand: (id: string) => void, handleOpenLead: (id: string) => void, isChild?: boolean) => {
  const {
    id,
    country,
    firstName,
    lastName,
    email,
    phone,
    state,
    isTest,
    apiTokenId,
    affSub2,
    affSub3,
    affSub4,
    affSub5,
    ip,
    createdAt,
    affSub1,
    affSub6,
    affSub7,
    affSub8,
    affSub9,
    affSub10,
    affSub11,
    affSub12,
    affSub13,
    affSub14,
    affSub15,
    leadSource,
    comment
  } = row

  const isPushed = checkIsPushed(state)

  switch (header) {
    case '::indicatorHolds':
      return <Box
        display='flex'
        alignItems="center"
        sx={isChild ? { marginLeft: '12px' } : {}}
      >
        <Indicator active={isPushed} />
        <input
          type='checkbox'
          style={{
            marginLeft: '18px',
            width: '24px',
            height: '24px'
          }}
          checked={isChecked}
          onClick={() => handleCheck(id, isChild ? CheckedIdType.SENT_LEADS : CheckedIdType.HOLDS)}
        />
      </Box>
    case '::expand':
      return typeof isExpanded === 'boolean'
        ? <Box
        display='flex'
        alignItems="center"
        onClick={() => handleExpand(id)}
      >
      {!isExpanded
        ? <KeyboardArrowRightIcon
          sx={{
            marginLeft: '18px',
            width: '24px',
            height: '24px'
          }} />
        : <KeyboardArrowDownIcon
          sx={{
            marginLeft: '18px',
            width: '24px',
            height: '24px'
          }} />}
        </Box>
        : null
    case holdsHeaders.id:
      return <TextField text={id} limitation={1}
          isEyeIcon={!isChild} onClickOpen={handleOpenLead} />
    case holdsHeaders.country:
      return country ? <CountryField countryCode={country} /> : null
    case holdsHeaders.firstName:
      return <TextField text={firstName} />
    case holdsHeaders.lastName:
      return <TextField text={lastName} />
    case holdsHeaders.email:
      return <TextField text={email} />
    case holdsHeaders.phone:
      return <TextField text={phone} />
    case holdsHeaders.state:
      return <StateField state={state} />
    case holdsHeaders.isTest:
      return <BooleanField isSomething={isTest} />
    case holdsHeaders.apiTokenId:
      return <TextField text={apiTokenId} limitation={1} />
    case holdsHeaders.affSub1:
      return <TextField text={affSub1} />
    case holdsHeaders.affSub2:
      return <TextField text={affSub2} />
    case holdsHeaders.affSub3:
      return <TextField text={affSub3} />
    case holdsHeaders.affSub4:
      return <TextField text={affSub4} />
    case holdsHeaders.affSub5:
      return <TextField text={affSub5} />
    case holdsHeaders.affSub6:
      return <TextField text={affSub6} />
    case holdsHeaders.affSub7:
      return <TextField text={affSub7} />
    case holdsHeaders.affSub8:
      return <TextField text={affSub8} />
    case holdsHeaders.affSub9:
      return <TextField text={affSub9} />
    case holdsHeaders.affSub10:
      return <TextField text={affSub10} />
    case holdsHeaders.affSub11:
      return <TextField text={affSub11} />
    case holdsHeaders.affSub12:
      return <TextField text={affSub12} />
    case holdsHeaders.affSub13:
      return <TextField text={affSub13} />
    case holdsHeaders.affSub14:
      return <TextField text={affSub14} />
    case holdsHeaders.affSub15:
      return <TextField text={affSub15} />
    case holdsHeaders.ip:
      return <TextField text={ip} />
    case holdsHeaders.createdAt:
      return <DateTimeField date={new Date(createdAt)} />
    case holdsHeaders.leadSource:
      return <TextField text={leadSource} />
    case holdsHeaders.comment:
      return <TextField text={comment} />
  }
}

function StateField ({ state }: { state: Hold['state'] }) {
  const { palette } = useTheme()
  const isPushed = checkIsPushed(state)
  return (
    <FlexBox gap={2} width="max-content">
      <Box
        sx={{
          width: '16px',
          height: '16px',
          borderRadius: '100px',
          backgroundColor: isPushed ? '#1FB526' : '#FF070D'
        }}
      />
      <Typography variant='table1' color={palette.text1.main}>
        {state} Hold
      </Typography>
    </FlexBox>
  )
}

export default fieldToElementMap
