import React from 'react'
import { BooleanField, DateTimeField, TextField } from '@/designSystem/Table/Fields'
import { Box, Typography } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import JsonView from '@uiw/react-json-view'
import { ReactComponent as ThreeDots } from '@/assets/icons/three-dots.svg'
import { advertiserLogsHeaders } from '@/store/advertisersLogs'
import { TableLink } from '@/components/Common/Tables/TableLink'
function LogData ({ logData }: any) {
  const { palette } = useTheme()
  return (
    <Typography
    display="flex"
    variant="body1"
    color={palette.text1.main}
    width="max-content"
    sx={{
      maxHeight: '500px',
      maxWidth: '250px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    }}
  >
    {JSON.stringify(logData)}
    {/* <JsonView value={logData} /> */}
    </Typography>
  )
}

export function FieldMap ({ children }: any) {
  const { palette } = useTheme()
  return (
    <Typography
      sx={{ display: 'flex' }}
      variant='table1'
      width="max-content"
      color={palette.text2.main}
    >
      {children}
    </Typography>
  )
}

const elementMap = (header: string, row: any,
  setModalLogs: any,
  handleOpenLead: (id: string) => void
) => {
  const {
    id,
    createdAt,
    logData,
    advertiser
  } = row

  switch (header) {
    case advertiserLogsHeaders.name:
      return <TextField text={id} limitation={80} />
    case advertiserLogsHeaders.advertiser:
      return advertiser.id ? <TableLink link={`/traffic-distribution/advertisers/${advertiser.id}`} label={advertiser.name} ></TableLink> : ''
    case advertiserLogsHeaders.error:
      return <FieldMap>
       {Object?.keys(logData)?.length ? <LogData logData={logData} /> : '{ }'}
       <Box sx={{ cursor: 'pointer' }} onClick={() => setModalLogs(logData)} >
          <ThreeDots />
        </Box>
      </FieldMap>
    case advertiserLogsHeaders.createdAt:
      return <DateTimeField date={new Date(createdAt)} />
  }
}

export default elementMap
