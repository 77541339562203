import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { serverURL } from '@/config'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { CheckedIdType } from '@/components/Pages/TrafficData/Hold/Table'
import { LeadState } from '@/enums/leads.enum'

export const holdsHeaders = {
  id: 'UUID',
  country: 'Country',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  state: 'State',
  isTest: 'Is Test',
  apiTokenId: 'Token ID',
  affSub1: 'Aff Sub 1',
  affSub2: 'Aff Sub 2',
  affSub3: 'Aff Sub 3',
  affSub4: 'Aff Sub 4',
  affSub5: 'Aff Sub 5',
  affSub6: 'Aff Sub 6',
  affSub7: 'Aff Sub 7',
  affSub8: 'Aff Sub 8',
  affSub9: 'Aff Sub 9',
  affSub10: 'Aff Sub 10',
  affSub11: 'Aff Sub 11',
  affSub12: 'Aff Sub 12',
  affSub13: 'Aff Sub 13',
  affSub14: 'Aff Sub 14',
  affSub15: 'Aff Sub 15',
  ip: 'Ip',
  createdAt: 'Created At',
  leadSource: 'Lead Source',
  comment: 'Comment'
}
export interface ExtraSettings {
  key: string
  value: string
}

export interface Hold {
  id: string
  country: string
  firstName: string
  lastName: string
  email: string
  password: string
  phone: string
  affiliate: number
  isAutologinSuccess: boolean
  state: LeadState
  isTest: boolean
  payout: number
  revenue: number
  hash?: string
  advertiser?: string
  offer?: string
  advertiserSaleStatus?: string
  advertiserId?: string
  saleStatus?: string
  affSub?: string
  affSub1?: string
  affSub2?: string
  affSub3?: string
  affSub4?: string
  affSub5?: string
  affSub6?: string
  affSub7?: string
  affSub8?: string
  affSub9?: string
  affSub10?: string
  affSub11?: string
  affSub12?: string
  affSub13?: string
  affSub14?: string
  affSub15?: string
  manual?: boolean
  ip?: string
  wasRejected?: boolean
  funnel?: string
  rejectedAdvertiserId?: string
  createdAt: Date
  rejectedAdvertiser?: string
  rejectedReason?: string
  autoLoginUrl?: string
  autoLoginDomain?: string
  stateUpdatedAt?: Date
  leadSource?: string
  funnelId?: string
  languageId?: string
  language?: string
  trafficProviderId?: string
  trafficProvider?: string
  externalId?: string
  apiTokenId?: string
  apiToken?: string
  fsmId?: string
  fsm?: string
  comment?: string
  isRisky?: boolean
  isLate?: boolean
  withLeads?: boolean
  isCheck?: boolean
  sentLeads?: any[]
}

export interface HoldsFilters {
  id?: string[]
  country?: string[]
  state?: Array<Hold['state']>
  isTest?: boolean
  ip?: string
  isAutologinSuccess?: boolean
  leadSource?: string
  externalId?: string[]

  advertiserId?: string[] // ids
  saleStatus?: string[]
  advertiserSaleStatus?: string[]

  affSub1?: string
  affSub2?: string
  affSub3?: string
  affSub4?: string
  affSub5?: string
  affSub6?: string
  affSub7?: string
  affSub8?: string
  affSub9?: string
  affSub10?: string
  affSub11?: string
  affSub12?: string
  affSub13?: string
  affSub14?: string
  affSub15?: string

  createdAt?: {
    from?: string
    to?: string
  }
  stateUpdatedAt?: {
    from?: string
    to?: string
  }

  firstName?: string
  lastName?: string
  email?: string
  password?: string
  phone?: string

}

export interface HoldState {
  totalCount?: number
  holds: Hold[]
}

const initialState: HoldState = {
  holds: []
}

export const advertisersSlice = createSlice({
  name: 'hold',
  initialState,
  reducers: {
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    setHold: (state, action: PayloadAction<Hold[]>) => {
      state.holds = action.payload
    }
  }
})

interface GetLeadsArgs {
  limit?: number
  page?: number
  filters?: HoldsFilters
  include?: Record<string, boolean>
}

interface GetLeadsReturn {
  leads: Hold[]
  totalCount: number
}

interface DeleteHoldArgs {
  id: string
  idsType: CheckedIdType
}

const transformValues = (name: string, value: string | string [] | boolean | { from?: string, to?: string }) => {
  if (typeof value === 'string' || typeof value === 'boolean') return value
  if (Array.isArray(value)) {
    return {
      in: value
    }
  }
  if (name === 'revenue') {
    return {
      ...value.from ? { gte: value.from } : {},
      ...value.to ? { lte: value.to } : {}
    }
  }
  return {
    ...value.from ? { gt: value.from } : {},
    ...value.to ? { lt: value.to } : {}
  }
}

const transformFilters = (filters: HoldsFilters | undefined) => {
  if (!filters) return {}
  const newFilters = Object.entries(filters).reduce((acc: HoldsFilters, [key, value]) => {
    return {
      ...acc,
      [key]: transformValues(key, value)
    }
  }, {})
  return newFilters
}

export const holdApi = createApi({
  reducerPath: 'holdApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['Hold'],
  endpoints: (builder) => ({
    getHolds: builder.query<GetLeadsReturn, GetLeadsArgs>({
      query: ({ limit = 10, page = 1, filters, include }) => {
        if (!filters) {
          return {
            url: '/leads/search',
            method: 'POST',
            body: {
              limit,
              page,
              sortField: 'createdAt',
              sortOrder: 'desc',
              include: {
                sentLeads: true,
                ...include?.logs ? { logs: include?.logs } : {}
              },
              filter: {
                state: {
                  in: [LeadState.HOLD]
                }
              }
            }
          }
        }
        const { createdAt, ...restFilters } = filters
        return {
          url: '/leads/search',
          method: 'POST',
          body: {
            limit,
            page,
            sortField: 'createdAt',
            sortOrder: 'desc',
            include: {
              sentLeads: true,
              ...include?.logs ? { logs: include?.logs } : {}
            },
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            ...(createdAt?.from || createdAt?.to)
              ? {
                  createdAt: {
                    ...createdAt.from ? { from: createdAt.from } : {},
                    ...createdAt.to ? { to: createdAt.to } : {}
                  }
                }
              : {},
            filter: {
              state: {
                in: [LeadState.HOLD]
              },
              ...transformFilters(restFilters)
            }
          }
        }
      },
      providesTags: [{ type: 'Hold', id: 'HoldsLIST' }]
    }),
    deleteHold: builder.mutation<any, DeleteHoldArgs>({
      query: ({ id, idsType }) => {
        if (idsType === CheckedIdType.HOLDS) {
          return {
            url: `/leads/${id}`,
            method: 'DELETE'
          }
        } else {
          return {
            url: `/sentLeads/${id}`,
            method: 'PATCH',
            body: {
              excluded: true
            }
          }
        }
      },
      invalidatesTags: [{ type: 'Hold', id: 'HoldsLIST' }]
    })
  })
})

export const { setTotalCount, setHold } = advertisersSlice.actions

export const selectTotalCount = (state: RootState) => state.hold.totalCount
export const selectHolds = (state: RootState) => state.hold.holds

export default advertisersSlice.reducer

export const { useGetHoldsQuery, useDeleteHoldMutation } = holdApi
