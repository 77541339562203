import React, { useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { selectPullLeadStateSettings, selectPullLeadStatusesSettings } from '@/store/advertisers'
import EditPullSettingsFormModal from './EditPullSettingsFormModal'
import { EApiSettingsFields, type IApiSettings } from './EditPushSettingsForm'
import { useSelector } from 'react-redux'
import { StateCheckTypeEnum } from '@/enums/advertiser.enum'
import { defaultTimeout } from '@/utils/advertisers'

const pullLeadStatusesDefault = [
  {
    key: EApiSettingsFields.URL,
    group: 'connection',
    value: ''
  },
  {
    key: EApiSettingsFields.STATUS_FIELD,
    group: 'connection',
    value: ''
  },
  {
    key: EApiSettingsFields.LEAD_ID_FIELD,
    group: 'connection',
    value: ''
  },
  {
    key: EApiSettingsFields.LEAD_LIST_FIELD,
    group: 'connection',
    value: ''
  },
  {
    key: EApiSettingsFields.PAGE_FIELD,
    group: 'connection',
    value: ''
  },
  {
    key: EApiSettingsFields.REQUEST_TYPE,
    group: 'connection',
    value: 'get'
  },
  {
    key: EApiSettingsFields.STARTING_PAGE_NUMBER,
    group: 'connection',
    value: '0'
  },
  {
    key: EApiSettingsFields.STATE_CHECK_TYPE,
    group: 'connection',
    value: StateCheckTypeEnum.STATE_FIELD_VALUE
  },
  {
    key: EApiSettingsFields.STATE_FIELD,
    value: '',
    group: 'connection'
  },
  {
    key: EApiSettingsFields.STATE_FIELD_FTD_VALUE,
    value: '',
    group: 'connection'
  },
  {
    key: EApiSettingsFields.REQUEST_TIMEOUT,
    value: defaultTimeout,
    group: 'connection'
  }
]
const pullLeadStateDefault = pullLeadStatusesDefault.filter(item => ![EApiSettingsFields.STATUS_FIELD].includes(item.key))

interface EditPullSettingsFormProps {
  handleClose: () => void
  settingsType: 'pullLeadStatusesSettings' | 'pullLeadStateSettings'
}

export default function EditPullSettingsForm ({
  handleClose, settingsType
}: EditPullSettingsFormProps) {
  const { advertiserId } = useParams()
  const allPullSettings = settingsType === 'pullLeadStatusesSettings' ? useSelector(selectPullLeadStatusesSettings) : useSelector(selectPullLeadStateSettings)
  const defaultSettings = settingsType === 'pullLeadStatusesSettings' ? pullLeadStatusesDefault : pullLeadStateDefault
  const mergedSettings = React.useMemo(() => {
    const existingKeys = new Set(allPullSettings.map((setting: IApiSettings) => setting.key))

    const missingSettings = defaultSettings.filter(
      (defaultSetting: IApiSettings) => !existingKeys.has(defaultSetting.key)
    )

    return [...allPullSettings, ...missingSettings]
  }, [allPullSettings, defaultSettings])
  const [settings, setSettings] = React.useState<IApiSettings[]>(mergedSettings)

  return (
    <>
      <EditPullSettingsFormModal
        advertiserId={advertiserId}
        handleClose={handleClose}
        settings={settings}
        setSettings={setSettings}
        settingsType = {settingsType}
      />
    </>
  )
}
