import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import { Typography, useTheme } from '@mui/material'
import AdvertiserLogsBar from './Filters/AdvertiserLogsBar'
import TableLayout from '@/designSystem/Table'
import elementMap from './ElementMap'
import { ModalEventInfo } from '../../../../designSystem/ModalMessagesLogs'
import {
  type AdvertisersLogs, selectLogs,
  setLogsTotalCount, useSearchLogsQuery, setLogs
} from '@/store/advertisersLogs'
import AdvertisersLogsPagination from './AdvertisersLogsPagination'
import { EmptyTableLogs } from './EmptyTableLogs'
import { routeRefs } from '@/routes/routesList'
import { useNavigate } from 'react-router-dom'
import { selectAdvertiserLogsQueryParams } from '@/store/queryParams'

const Header = ({ header }: { header: string }) => {
  if (header.startsWith('::')) {
    return ''
  }
  return (
    <Typography
      variant='body1'
      color="text1"
      width="max-content"
      sx={{
        display: 'flex'
      }}
    >
      {header}
    </Typography>
  )
}

export default function AdvertisersLogsTable () {
  const dispatch = useDispatch()
  const handleOpenLead = (id: string) => {
    window.open(`/traffic-distribution/advertisers/${id}`, '_blank')
  }
  const { limit, headers, filter, page } = useSelector(selectAdvertiserLogsQueryParams)
  const logsDataState: AdvertisersLogs[] = useSelector(selectLogs)
  const logsDataApi: any = useSearchLogsQuery({ sortField: 'logData', limit, page, filter: filter ? { advertiserId: filter?.advertiserId, id: filter?.uuid } : {}, createdAt: filter?.createdAt ? filter?.createdAt : undefined }).data
  const { spacing } = useTheme()
  const [logsData, setLogsData] = React.useState<any>(logsDataState ?? [])
  const [modalLogs, setModalLogs] = React.useState<any>(null)
  // const headersWithExtraFields = ['::indicatorApiTokens']?.concat(headers)
  const headerNodes: React.ReactNode[] =
    headers?.map((header: string) => <Header key={header} header={header} />)

  const rowNodes: any = logsData?.map((row: any) => (
    headers?.map((header: string) =>
      elementMap(header, row, setModalLogs, handleOpenLead))
  ))

  useEffect(() => {
    if (logsDataApi) {
      logsDataApi && dispatch(setLogs(logsDataApi?.logs ?? []))
      setLogsData(logsDataApi?.logs ?? [])
      dispatch(setLogsTotalCount(logsDataApi?.totalCount ?? 0))
    }
  }, [logsDataApi])
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={spacing(6)}
    >
      <AdvertisersLogsPagination />
      <AdvertiserLogsBar/>
      <TableLayout
        headers={headerNodes}
        rows={rowNodes}
        sx={{
          '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-of-type': {
            width: 0
          }
        }}
      />
      {(rowNodes?.length === 0) && <EmptyTableLogs />}
      {modalLogs && <ModalEventInfo
        open={!!modalLogs}
        handleClose={() => setModalLogs(null)}
        logs={modalLogs}
      />}
    </Box>
  )
}
