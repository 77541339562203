import React from 'react'

import { Box } from '@mui/material'
import MetricConditions from '../MetricConditions'
import MetricReport from './MetricReport'
import MetricPresetsGroups from './MetricPresetsGroups'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { type GetDrillReportsArgs, setDrillReportsState, useCreateDrillReportsMutation, useCreateCsvReportMutation, setDrillReportsDataState, selectDrillReportsDataState } from '@/store/drillReports'
import { useDispatch, useSelector } from 'react-redux'
import Form from '@/components/Form'
import { getLastWeek } from '@/components/Dashboard/utils'

interface IMetricLayout {
  data: {
    presetsGroups: string[]
    conditionFieldsOptions: Record<string, any>
  }
}

const MetricLayout = ({ data }: IMetricLayout) => {
  const dispatch = useDispatch()
  const [createDrillReport] = useCreateDrillReportsMutation()
  const [createCsvReport] = useCreateCsvReportMutation()
  const reportsDataState = useSelector(selectDrillReportsDataState)

  const form = useForm<GetDrillReportsArgs>({
    defaultValues: {
      groups: [],
      pql: '',
      termsIds: [],
      createdAt: {
        from: '',
        to: ''
      },
      dateAt: { value: '', title: '' },
      isEmptyPql: false
    }
  })

  const onSubmit: SubmitHandler<GetDrillReportsArgs> = async ({ groups, pql, termsIds, createdAt, dateAt }) => {
    const datePeriodCreatedAt = dateAt?.value ? { from: dateAt?.value, to: dateAt?.title === 'Last week' ? getLastWeek().to : new Date().toISOString() } : createdAt
    const report = await createDrillReport({ groups, pql, termsIds, createdAt: datePeriodCreatedAt })
    report && dispatch(setDrillReportsState(report))
    report && dispatch(setDrillReportsDataState({ groups, pql, termsIds, createdAt }))
  }

  const getSubmitScv = async () => {
    const formValues = reportsDataState ?? form.getValues()
    await createCsvReport(formValues)?.then((report: any) => {
      if (report.error && report.error.status === 'PARSING_ERROR') {
        const csvText = report.error.data
        csvText && downloadCsv(csvText)
      } else {
        console.error('Ошибка при получении данных с сервера')
      }
    })
  }

  function downloadCsv (csvData: string) {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link: any = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'report.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'40px'}
        justifyContent={'start'}
      >
        <MetricPresetsGroups data={data} form={form} />
        <MetricConditions data={data} form={form} />
        <MetricReport form={form} getCsv={getSubmitScv} />
      </Box>
    </Form>
  )
}

export default MetricLayout
