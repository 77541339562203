import { type IChartLead } from '@/store/dashboard'
import { LeadState } from '@/enums/leads.enum'

interface DateRange {
  from: string
  to: string
}
export function getCurrentDay (): DateRange {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate())
  currentDate.setHours(0, 0, 0, 0) // Устанавливаем время на полночь вчерашнего дня

  const fromISOString = new Date(currentDate.getTime() + (60 * 60 * 1000)).toISOString()
  const toISOString = new Date(currentDate.getTime() + (24 * 60 * 60 * 1000) - 1).toISOString()

  return {
    from: fromISOString,
    to: toISOString
  }
}

export function getYesterday (): DateRange {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() - 1)
  currentDate.setHours(0, 0, 0, 0) // Устанавливаем время на полночь вчерашнего дня

  const fromISOString = new Date(currentDate.getTime() + (60 * 60 * 1000)).toISOString()
  const toISOString = new Date(currentDate.getTime() + (24 * 60 * 60 * 1000) - 1).toISOString()

  return {
    from: fromISOString,
    to: toISOString
  }
}

export function getCurrentMonth (): DateRange {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()

  const fromDate = new Date(currentDate)
  fromDate.setMonth(currentMonth, 1) // Set to the first day of the current month
  fromDate.setHours(0, 0, 0, 0)

  const toDate = new Date(currentDate)
  toDate.setMonth(currentMonth + 1, 0) // Set to the last day of the current month
  toDate.setHours(23, 59, 59, 999)

  return {
    from: fromDate.toISOString(),
    to: toDate.toISOString()
  }
}

export function getLastMonth (): DateRange {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()

  const fromDate = new Date(currentDate)
  fromDate.setMonth(currentMonth - 1, 1) // Set to the first day of last month
  fromDate.setHours(0, 0, 0, 0) // Set to midnight

  const toDate = new Date(currentDate)
  toDate.setMonth(currentMonth, 0)
  toDate.setHours(23, 59, 59, 999) // Set to the end of the day

  return {
    from: fromDate.toISOString(),
    to: toDate.toISOString()
  }
}

export function getCurrentWeek (): DateRange {
  const currentDate = new Date()
  const currentDayOfWeek = currentDate.getDay() // 0 (воскресенье) до 6 (суббота)
  const startOfWeek = new Date(currentDate.getTime() - ((currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1) * 24 * 60 * 60 * 1000)) // Переходим к началу текущей недели (понедельник)
  startOfWeek.setHours(0, 0, 0, 0) // Устанавливаем время на полночь начала недели

  const fromISOString = startOfWeek.toISOString()
  const toISOString = new Date(startOfWeek.getTime() + (7 * 24 * 60 * 60 * 1000) - 1).toISOString() // Добавляем 7 дней и вычитаем 1 миллисекунду для конца недели (воскресенье)
  console.log('🚀 ~ getCurrentWeek ~ toISOString:', {
    from: fromISOString,
    to: toISOString
  })

  return {
    from: fromISOString,
    to: toISOString
  }
}

export function getLastWeek (): DateRange {
  const currentDate = new Date()
  const currentDayOfWeek = currentDate.getDay() // 0 (воскресенье) до 6 (суббота)
  // console.log('🚀 ~ getLastWeek ~ currentDate.getDay():', currentDate.getDay())
  const startOfLastWeek = new Date(currentDate.getTime() - ((currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1) * 24 * 60 * 60 * 1000) - (7 * 24 * 60 * 60 * 1000)) // Переходим к началу прошлой недели (понедельник)
  startOfLastWeek.setHours(0, 0, 0, 0) // Устанавливаем время на полночь начала недели

  const fromISOString = startOfLastWeek.toISOString()
  const toISOString = new Date(startOfLastWeek.getTime() + (7 * 24 * 60 * 60 * 1000) - 1).toISOString() // Добавляем 7 дней и вычитаем 1 миллисекунду для конца недели (воскресенье)
  return {
    from: fromISOString,
    to: toISOString
  }
}
export function getCurrentYear (): DateRange {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const fromISOString = new Date(currentYear, 0, 1).toISOString()
  const toISOString = new Date(currentYear + 1, 0, 1).toISOString() // Начало следующего года

  return {
    from: fromISOString,
    to: toISOString
  }
}

export function getLastYear (): DateRange {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const fromISOString = new Date(currentYear - 1, 0, 1).toISOString()
  const toISOString = new Date(currentYear, 0, 1).toISOString() // Начало текущего года

  return {
    from: fromISOString,
    to: toISOString
  }
}

interface DateRange {
  from: string
  to: string
}

// function formatDate (date: Date, format: string): string {
//   const day = date.getDate().toString().padStart(2, '0')
//   const month = (date.getMonth() + 1).toString().padStart(2, '0')
//   const year = date.getFullYear().toString()
//   const hours = date.getHours().toString().padStart(2, '0')
//   const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
//   const monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

//   const dayOfWeek = daysOfWeek[date.getDay()]
//   const monthsOfYear = monthsOfYear[date.getMonth()]

//   switch (format) {
//     case 'year':
//       return `${month}/${year}`
//     case 'month':
//       return `${day}`
//     case 'week':
//       return `${dayOfWeek}`
//     case 'day':
//       return `${hours}`
//     default:
//       return `${day}/${month}`
//   }
// }
function formatDate (date: Date, format: string): string {
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()
  const hours = date.getHours().toString().padStart(2, '0')
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const dayOfWeek = daysOfWeek[date.getDay()]
  const monthOfYear = monthsOfYear[date.getMonth()]

  switch (format) {
    case 'year':
      return `${monthOfYear}`
    case 'month':
      return `${day}`
    case 'week':
      return `${dayOfWeek}`
    case 'day':
      return `${hours}`
    default:
      return `${day} ${monthOfYear}`
  }
}

function getDateRange (getRangeFunc: () => DateRange, format: string, dataExtractor: (date: Date) => number, leadsData: IChartLead[], ftdData?: IChartLead[], isRevenueData?: boolean): { xData: string[], yData: number[], zData?: number[] } {
  const { from, to } = getRangeFunc()
  const startDate = new Date(from)
  const endDate = new Date(to)

  const leadCounts: Record<number, number> = {}
  const ftdCounts: Record<number, number> = {}

  const xData: string[] = []
  const yData: number[] = [] /* PUSHED_LEAD */
  const zData: number[] = [] /* FTD */
  const xDataLimit = format === 'year' ? 12 : format === 'month' ? 30 : format === 'week' ? 7 : 23
  leadsData && leadsData?.forEach((item: IChartLead) => {
    const range = dataExtractor(new Date(item.createdAt)) + 1
    if (isRevenueData === true) {
      leadCounts[range] = (leadCounts[range] || 0) + (item.revenue ?? 0)
    } else {
      leadCounts[range] = (leadCounts[range] || 0) + 1
    }

    // leadCounts[range] = (leadCounts[range] || 0) + 1
    // console.log('🚀 ~ getDateRange ~ leadCounts[range]:', leadCounts[range])
  })
  ftdData && ftdData?.forEach((item: IChartLead) => {
    const range = dataExtractor(new Date(item.createdAt)) + 1
    ftdCounts[range] = (ftdCounts[range] || 0) + 1
    // console.log('🚀 ~ getDateRange ~ leadCounts[range]:', ftdCounts[range])
  })
  for (let i = 1; i <= xDataLimit; i++) {
    leadsData && yData.push(leadCounts[i] || 0)
    ftdData && zData.push(ftdCounts[i] || 0)
  }

  if (format === 'year') {
    while (startDate.getTime() < endDate.getTime()) {
      xData.push(formatDate(startDate, format))
      startDate.setMonth(startDate.getMonth() + 1)
    }
  } else if (format === 'month') {
    while (startDate.getTime() <= endDate.getTime()) {
      xData.push(formatDate(startDate, format))
      startDate.setDate(startDate.getDate() + 1)
    }
  } else if (format === 'week') {
    while (startDate.getTime() <= endDate.getTime()) {
      xData.push(formatDate(startDate, format))
      startDate.setDate(startDate.getDate() + 1)
    }
  } else if (format === 'day') {
    while (startDate.getTime() <= endDate.getTime()) {
      xData.push(formatDate(startDate, format))
      startDate.setHours(startDate.getHours() + 1)
    }
  }

  console.log('🚀 ~ getDateRange ~ zData:', zData)
  console.log('🚀 ~ getDateRange ~ yData:', yData)

  return { xData, yData, zData }
}

export function generateChartYearData (leadsData: IChartLead[], ftdData?: IChartLead[], isRevenueData?: boolean): { xData: string[], yData: number[], zData?: number[] } {
  return getDateRange(getCurrentYear, 'year', date => date.getMonth(), leadsData, ftdData, isRevenueData)
}

export function generateChartMonthData (leadsData: IChartLead[], ftdData?: IChartLead[], isRevenueData?: boolean): { xData: string[], yData: number[], zData?: number[] } {
  return getDateRange(getCurrentMonth, 'month', date => date.getDate(), leadsData, ftdData, isRevenueData)
}

export function generateChartWeekData (leadsData: IChartLead[], ftdData?: IChartLead[], isRevenueData?: boolean): { xData: string[], yData: number[], zData?: number[] } {
  return getDateRange(getCurrentWeek, 'week', date => date.getDay(), leadsData, ftdData, isRevenueData)
}

export function generateChartDayData (leadsData: IChartLead[], ftdData?: IChartLead[], isRevenueData?: boolean): { xData: string[], yData: number[], zData?: number[] } {
  return getDateRange(getCurrentDay, 'day', date => date.getHours(), leadsData, ftdData, isRevenueData)
}

export function generateChartLastYearData (leadsData: IChartLead[], ftdData?: IChartLead[], isRevenueData?: boolean): { xData: string[], yData: number[], zData?: number[] } {
  return getDateRange(getLastYear, 'year', date => date.getMonth(), leadsData, ftdData, isRevenueData)
}

export function generateChartLastMonthData (leadsData: IChartLead[], ftdData?: IChartLead[], isRevenueData?: boolean): { xData: string[], yData: number[], zData?: number[] } {
  return getDateRange(getLastMonth, 'month', date => date.getDate(), leadsData, ftdData, isRevenueData)
}

export function generateChartLastWeekData (leadsData: IChartLead[], ftdData?: IChartLead[], isRevenueData?: boolean): { xData: string[], yData: number[], zData?: number[] } {
  return getDateRange(getLastWeek, 'week', date => date.getDay(), leadsData, ftdData, isRevenueData)
}

export function generateChartYesterdayData (leadsData: IChartLead[], ftdData?: IChartLead[], isRevenueData?: boolean): { xData: string[], yData: number[], zData?: number[] } {
  return getDateRange(getYesterday, 'day', date => date.getHours(), leadsData, ftdData, isRevenueData)
}
export interface TableRowCount {
  advertiserId?: string
  advertiserName?: string
  country?: string
  leads?: number
  leadsLast?: number
  ftdLast?: number
  position: number
  ftd?: number
  id: string
}

export function generateBandChartData (groupBy: 'advertiserName' | 'country', dataset: IChartLead[], datasetLast?: IChartLead[]): TableRowCount[] {
  const groupedData: Record<string, any> = {}
  const defaultData = {
    leads: 0,
    leadsLast: 0,
    ftd: 0,
    ftdLast: 0
  }
  const processData = (data: IChartLead[], leadField: string, ftdField: string) => {
    for (const item of data) {
      const { state, id, advertiserId } = item
      const groupByValue = item[groupBy]
      if (groupByValue === undefined) continue

      if (groupedData[groupByValue]) {
        groupedData[groupByValue][leadField]++
        if (state === LeadState.FTD) {
          groupedData[groupByValue][ftdField]++
        }
      } else {
        groupedData[groupByValue] = {
          id,
          advertiserId,
          [groupBy]: groupByValue,
          ...defaultData,
          [leadField]: 1,
          [ftdField]: state === LeadState.FTD ? 1 : 0
        }
      }
    }
  }

  processData(dataset, 'leads', 'ftd')
  // console.log('🚀 ~ currentData:', advertisers)
  if (datasetLast) {
    processData(datasetLast, 'leadsLast', 'ftdLast')
  //  console.log('🚀 ~ lastData:', advertisers)
  }

  return Object.values(groupedData)
}
export function generateBandChartContryData (dataset: IChartLead[], datasetLast?: IChartLead[]): TableRowCount[] {
  const processData = (data: IChartLead[], isLast: boolean) => {
    const countries: Record<string, any> = {}

    for (const item of data) {
      const { country, state, id } = item

      if (country === undefined) continue // Пропускаем элементы с undefined advertiserName

      if (!countries[country]) {
        countries[country] = {
          id,
          country,
          [isLast ? 'leadsLast' : 'leads']: 1,
          [isLast ? 'ftdLast' : 'ftd']: state === LeadState.FTD ? 1 : 0
        }
      } else {
        countries[country][isLast ? 'leadsLast' : 'leads']++
        if (state === LeadState.FTD) {
          countries[country][isLast ? 'ftdLast' : 'ftd']++
        }
      }
    }

    return Object.values(countries)
  }

  const currentData = dataset ? processData(dataset, false) : []
  const lastData = datasetLast ? processData(datasetLast, true) : []

  console.log('🚀 ~ currentData:', currentData)
  console.log('🚀 ~ lastData:', lastData)

  return [...currentData, ...lastData]
}
