import React, { useState } from 'react'
import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'

import Popup from '@/designSystem/Popup'
import Button from '@/designSystem/Button'
import Accordion from '@/designSystem/Accordion'

import { type SxProps, Typography } from '@mui/material'

import { useSelector, useDispatch } from 'react-redux'
import { type HoldsFilters } from '@/store/hold'
import { type SubmitHandler, type UseFormReturn } from 'react-hook-form'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'
import { type FilterItemType, filterCategories } from './constants'
import FilterItem from './FilterItem'
import { selectHoldQueryParams, setHoldQueryParams } from '@/store/queryParams'

interface FiltersPopupProps {
  handleClose: () => void
}

export interface FilterPopupFormProps {
  form: UseFormReturn<HoldsFilters>
  onSubmit: SubmitHandler<HoldsFilters>
  handleClose: () => void
  resetFilters: () => void
}

export default function HoldsFiltersPopup ({
  handleClose
}: FiltersPopupProps) {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectHoldQueryParams)
  const theme = useTheme()
  const { palette, spacing } = theme

  // const isStatus = queryParams?.filters?.manual
  // const createdAt = queryParams?.filters?.createdTo?.to

  const bgColor = '#2b2d42'

  const resetFilters = () => {
    dispatch(setHoldQueryParams({ ...queryParams, page: 1, filters: undefined }))
    handleClose()
  }

  const accordionSx: SxProps = {
    backgroundColor: bgColor,
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '& .MuiAccordionSummary-root': {
      color: palette.card.main
    },
    '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
      fill: palette.card.main,
      marginRight: '1.3px'
    }
  }

  const [filters, setFilters] = useState<HoldsFilters>(queryParams.filters ?? {})

  const onSubmitClick = () => {
    dispatch(setHoldQueryParams({
      ...queryParams,
      page: 1,
      filters
    }))
    handleClose()
  }

  const onDelete = (field: keyof HoldsFilters, value: any, isMultiselect: boolean) => {
    if (!isMultiselect) return
    const filtersValue = filters[field]
    if (filtersValue && Array.isArray(filtersValue) && filtersValue[0] !== undefined && typeof filtersValue[0] === 'string' && filtersValue?.includes(value)) { // delete if value is the same
      const newFilters = {
        ...filters,
        [field]: (filters[field] as any[]).filter((i) => i !== value)
      }
      setFilters(newFilters)
    }
  }

  const onChangeFilters = (field: keyof HoldsFilters, value: any, isMultiselect: boolean) => {
    if (value === undefined || value === null || (typeof value === 'string' && value.length === 0)) return
    if (isMultiselect) {
      const newFilters = {
        ...filters,
        [field]: Array.isArray(filters[field]) ? (filters[field] as any[]).concat(value) : [value]
      }
      setFilters(newFilters)
    } else {
      const newFilters = {
        ...filters,
        [field]: value
      }
      setFilters(newFilters)
    }
  }

  return (
    <Popup
      open
      title={<Title /> as any}
      handleClose={handleClose}
      titleSx={{ color: palette.card.main }}
      maxWidth='404px'
      rootBoxSx={{
        padding: spacing(4, 6),
        backgroundColor: bgColor
      }}
      closeButtonSx={{
        '& .MuiSvgIcon-root': { fill: palette.card.main }
      }}
    >
      <ScrollBarContainer>
          <Box
            display="flex"
            flexDirection="column"
            gap={spacing(3)}
          >
            {Object.entries(filterCategories).map(([accordionName, fields]) =>
              <Accordion key={accordionName} title={accordionName} sx={accordionSx}>
                {fields.map((i: FilterItemType) =>
                  <FilterItem
                    onDelete={onDelete}
                    key={i.name}
                    title={i.title}
                    name={i.name}
                    onChange={onChangeFilters}
                    isMultiple={i.isMultiple}
                    selectedValues={filters[i.name]}
                  />)}
              </Accordion>
            )}
            <Box
              display="flex"
              flexDirection="column"
              gap={theme.spacing(2)}
            >
              <Button
                text="Apply"
                variant="contained"
                onClick={onSubmitClick}
              />
              <Button
                text="Reset Filters"
                variant="text"
                textColor={theme.palette.red2.main}
                onClick={resetFilters}
              />
            </Box>
          </Box>
      </ScrollBarContainer>
    </Popup>
  )
}

function Title () {
  const theme = useTheme()
  return (
    <Typography
      variant='headline'
      color={theme.palette.whiteText.main}
    >
      Filters
    </Typography>
  )
}
