import React, { useMemo } from 'react'
import Chip from '@/components/Advertisers/Filters/Chip'
import { useDispatch, useSelector } from 'react-redux'
import { type LeadsFilters } from '@/store/leads'
import { setLeadsQueryParams, selectLeadsQueryParams } from '@/store/queryParams'
import { Box, useTheme } from '@mui/material'
import cloneDeep from 'lodash.clonedeep'
import unset from 'lodash.unset'

const Bar = ({ children }: { children: React.ReactNode }) => {
  const { spacing, palette } = useTheme()
  return (
    <Box
      display={'flex'}
      flexWrap={'wrap'}
      rowGap={spacing(3)}
      gap={spacing(3)}
      justifyContent="flex-start"
      padding={spacing(3, 4)}
      borderRadius="12px"
      sx={{
        backgroundColor: palette.card.main
      }}
    >
      {children}
    </Box>
  )
}

const areFiltersExist = (filters?: LeadsFilters) => {
  if (!filters) {
    return false
  }
  return Object.entries(filters).some(([key, value]) => {
    if (Array.isArray(value)) return !!value.length
    return !!value
  })
}

export default function LeadsFilterBar () {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectLeadsQueryParams)

  const isParamExist = useMemo(() => areFiltersExist(queryParams?.filters), [queryParams?.filters])

  if (!isParamExist) {
    return null
  }

  const onDeleteParam = (label: string) => () => {
    const params = cloneDeep(queryParams)
    unset(params, `filters.${label}`)
    params.page = 1
    dispatch(setLeadsQueryParams(params))
  }

  const filters = queryParams.filters!

  return (
    <Bar>
      {Object.entries(filters).map(([key, value]) => <Chip
          key={key}
          label={`${key}: ${JSON.stringify(value).replaceAll('"', '')}`}
          onDelete={onDeleteParam(key)}
        />)}
    </Bar>
  )
}
