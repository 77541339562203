import React from 'react'
import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'
import Button from '@/designSystem/Button'
import Form from '@/components/Form'
import Checkbox from '@/components/Form/Checkbox'
import Popup from '@/designSystem/Popup'
import { useFieldArray, type SubmitHandler, type UseFormReturn, useWatch } from 'react-hook-form'
import { FormGroup, Typography } from '@mui/material'
import ItemsPerPageAutocomplete from '@/designSystem/Pages/Advertisers/Popups/Settings/ItemsPerPageAutocomplete'

import type { Option } from '@/designSystem/Autocomplete/types'
import { DragDropContext, Draggable, Droppable, type DropResult } from 'react-beautiful-dnd'

interface Column { name?: string, value: boolean, title: string, dbName: string }

export interface ColumnSettingsInputs {
  limit: Option<number>
  columns: Column[]
}

export interface ColumnSettingsModalProps {
  form: UseFormReturn<ColumnSettingsInputs>
  onSubmit: SubmitHandler<ColumnSettingsInputs>
  handleClose: () => void
  handleReset: () => void
}

const isLastChosen = (
  fields: ColumnSettingsInputs['columns'],
  field: Column
) => {
  const isChosen = fields.find(({ name, value }) => name === field.name && value)
  if (!isChosen) {
    return false
  }
  let chosenCounter = 0
  for (let i = 0; i < fields.length; i++) {
    if (fields[i].value) {
      chosenCounter++
    }
    if (chosenCounter === 2) {
      return false
    }
  }
  return true
}

export default function ColumnSettingsModal ({
  form,
  onSubmit,
  handleClose,
  handleReset
}: ColumnSettingsModalProps) {
  const theme = useTheme()
  const { control } = form
  const { fields, move } = useFieldArray({ control, name: 'columns' })
  const columns = useWatch({ name: 'columns', control })
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return
    move(result.source.index, result.destination.index)
  }
  const onResetFilters = () => {
    handleReset()
    handleClose()
  }
  return (
    <Popup
      open
      title={<Title /> as any}
      handleClose={handleClose}
      titleSx={{ color: theme.palette.card.main }}
      maxWidth='404px'
      rootBoxSx={{
        padding: theme.spacing(4, 6),
        backgroundColor: theme.palette.inputText.main
      }}
      closeButtonSx={{
        '& .MuiSvgIcon-root': { fill: theme.palette.card.main }
      }}
    >
      <Form form={form} onSubmit={onSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          gap={theme.spacing(8)}
        >
          <ItemsPerPageAutocomplete />
          <Box display="flex" flexDirection="column" gap={theme.spacing(6)}>
            <Typography color={theme.palette.whiteText.main} variant='body2'>
              Displayed Columns:
            </Typography>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="columns">
                {(provided) => (
                  <FormGroup
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    sx={{
                      color: theme.palette.card.main,
                      marginLeft: '2px'
                    }}
                  >
                    {fields.map((field, index) => (
                      <Draggable key={field.id} draggableId={field.id} index={index}>
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Checkbox
                              disabled={isLastChosen(columns, field)}
                              label={field.title}
                              name={field.name}
                              checkboxProps={{
                                defaultChecked: field.value
                              }}
                            />
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </FormGroup>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={theme.spacing(2)}
          >
            <Button
              text="Apply Settings"
              variant="contained"
              type="submit"
            />
            <Button
              text="Reset Columns"
              variant="text"
              textColor={theme.palette.red2.main}
              onClick={onResetFilters}
            />
          </Box>
        </Box>
      </Form>
    </Popup>
  )
}

function Title () {
  const theme = useTheme()
  return (
    <Typography
      variant='leftBar'
      color={theme.palette.whiteText.main}
    >
      Columns Settings
    </Typography>
  )
}
