import React, { useState } from 'react'

import { Box, Card, IconButton, Typography, useTheme } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'

import { format } from 'date-fns'

import ClipboardIcon from '@/designSystem/Icons/Clipboard'
import ExpandIcon from '@/designSystem/Icons/Expand'
import { CalendarIcon, ClockIcon } from '@mui/x-date-pickers'
import PointIcon from '../Pages/Advertisers/IntegrationSettings/LogTabContent/Icons/Point'
import { RotationLogType } from '@/enums/rotations.enum'
import JsonView from '@uiw/react-json-view'

export interface LogsProps {
  logs?: any[]
  logType: RotationLogType
}

export default function LogsLayout ({
  logs,
  logType
}: LogsProps) {
  const [areCollapsedIds, setAreCollapsedIds] = useState<boolean[]>(() => new Array(logs?.length).fill(true))
  const { palette, spacing } = useTheme()

  if (!logs || logs.length === 0) {
    return (
      <Typography variant="h1">
        No logs yet
      </Typography>
    )
  }

  const onCopy = (text: string) => navigator.clipboard.writeText(text)
  const onExpand = (index: number) => setAreCollapsedIds((areCollapsed) => {
    const collapsed = [...areCollapsed]
    collapsed[index] = !collapsed[index]
    return collapsed
  })
  const renderLogs = () => {
    return logs.map(({ id, createdAt, logData, ...rest }, index) => (
      <Box key={id}>
        <Box position="absolute" left={spacing(-4)}>
          <PointIcon />
        </Box>
        <Card
          variant="outlined"
          sx={{
            padding: spacing(4, 6),
            borderRadius: '16px',
            maxWidth: '730px',
            marginLeft: spacing(6)
          }}
        >
          <FlexBox column gap={4}>
            <FlexBox gap={8}>
              <FlexBox gap={2}>
                <CalendarIcon />
                <Typography variant="body2" color={palette.text2.main}>
                  {format(createdAt, 'dd/MM/yyyy')}
                </Typography>
              </FlexBox>
              <FlexBox gap={2}>
                <ClockIcon />
                <Typography variant="body2" color={palette.text2.main}>
                  {format(createdAt, 'kk:mm:ss')}
                </Typography>
              </FlexBox>
            </FlexBox>
            <FlexBox gap={2} alignCenter>
              <Row title="ID" text={id} isLimited={areCollapsedIds[index]} />
              <FlexBox gap={1} alignCenter>
                <IconButton onClick={() => onExpand(index)}>
                  <ExpandIcon />
                </IconButton>
                <IconButton onClick={() => onCopy(id)}>
                  <ClipboardIcon opacity="0.7" />
                </IconButton>
              </FlexBox>
            </FlexBox>
             {logType === RotationLogType.LOGS &&
              Object.entries(logData).map(([key, value]) => {
                return value !== null
                  ? (
                  <Row
                    key={key}
                    title={key}
                    text={JSON.stringify(`${(value as any)?.from} -> ${(value as any)?.to}`).replaceAll('"', '')}
                  />
                    )
                  : null
              })}
            {logType === RotationLogType.REJECTED_LEADS && (
              <FlexBox column gap={4}>
                <FlexBox gap={8}>
                  <FlexBox gap={2}>
                    <CalendarIcon />
                    <Typography variant='body2' color={palette.text2.main}>
                      {format(createdAt, 'dd/MM/yyyy')}
                    </Typography>
                  </FlexBox>
                  <FlexBox gap={2}>
                    <ClockIcon />
                    <Typography variant='body2' color={palette.text2.main}>
                      {format(createdAt, 'kk:mm:ss')}
                    </Typography>
                  </FlexBox>
                </FlexBox>
                <FlexBox gap={2} alignCenter>
                  <Row title="ID" text={id} isLimited={areCollapsedIds[index]} />
                  <FlexBox gap={1} alignCenter>
                    <IconButton onClick={() => onExpand(index)}>
                      <ExpandIcon />
                    </IconButton>
                    <IconButton onClick={() => onCopy(id)}>
                      <ClipboardIcon opacity="0.7" />
                    </IconButton>
                  </FlexBox>
                </FlexBox>

                {!(logData?.requestData || logData?.responseData) &&
                 <FlexBox gap={2} alignCenter>
                   <Row title="Data" data={<JsonView value={logData} displayDataTypes={false} displayObjectSize={false}
                                                     shortenTextAfterLength={1000} onCopied={(text, value) => onCopy(text)} enableClipboard={false} />}
                        isLimited={false} />
                   <IconButton onClick={() => onCopy(JSON.stringify(logData))}>
                     <ClipboardIcon opacity="0.7" />
                   </IconButton>
                 </FlexBox>}
                {logData?.requestData && <FlexBox gap={2} alignCenter>
                  <Row title="Request Data" isLimited={false}
                       data={<JsonView value={logData?.requestData} displayDataTypes={false} displayObjectSize={false}
                                       onCopied={(text, value) => onCopy(text)} shortenTextAfterLength={1000} enableClipboard={false} />}
                  />
                  <IconButton onClick={() => onCopy(JSON.stringify(logData?.requestData))}>
                    <ClipboardIcon opacity="0.7" />
                  </IconButton>
                </FlexBox>}
                {logData?.responseData && <FlexBox gap={2} alignCenter>
                  <Row title="Response Data" isLimited={false}
                       data={<JsonView value={logData?.responseData} displayDataTypes={false} displayObjectSize={false}
                                       onCopied={(text, value) => onCopy(text)} shortenTextAfterLength={1000} enableClipboard={false} />} />
                  <IconButton onClick={() => onCopy(JSON.stringify(logData?.responseData))}>
                    <ClipboardIcon opacity="0.7" />
                  </IconButton>
                </FlexBox>}
              </FlexBox>
            )}
          </FlexBox>
        </Card>
      </Box>
    ))
  }
  return (
    <FlexBox
      position="relative"
      column
      borderLeft={`3px dashed ${palette.text2.main}`}
      margin={spacing(3)}
      padding={spacing(0, 3)}
      gap={6}
    >
      {renderLogs()}
    </FlexBox>
  )
}

interface RowProps {
  title: string
  text?: string
  isLimited?: boolean
  data?: any
}
function Row ({ text, title, isLimited, data }: RowProps) {
  const { palette } = useTheme()

  return (
    <Typography
      color={palette.text2.main}
      sx={isLimited
        ? {
            maxWidth: '80px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }
        : { maxWidth: '95%' }}
    >
      <Box
        display="inline"
        color={palette.text1.main}
        fontWeight={500}
      >
        {`${title}: `}
      </Box>
      {text || data}
    </Typography>
  )
}
