import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import { type RootState } from '.'

export enum PeriodType {
  HOUR,
  DAY,
  WEEK,
  MONTH
}
export interface ISettings {
  appSettings: {
    id: string
    lateMinimumInterval: {
      period: number
      periodType: number
    }
    leadDeduplication: {
      fields: string[]
      checkAll: boolean
      intervalPeriod: number
      intervalPeriodType: number
    }
    timezone: string
  }
}
interface GetSettingsArgs {
  periodType: number
  period: number
}

interface DeduplicationSettingsArgs {
  fields: string[]
  checkAll: boolean
  intervalPeriod: number
  intervalPeriodType: number
}

interface ITimezone {
  timezone: string
}

const initialState: ITimezone = {
  timezone: 'Europe/Kiev'
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setTimezone: (state, action) => {
      state.timezone = action.payload
    }
  }
})

export const { setTimezone } = settingsSlice.actions

export default settingsSlice.reducer

export const selectTimezone = (state: RootState) => state.settings.timezone

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/app-settings`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['Settings'],
  endpoints: (builder) => ({
    editLeadIntervalSettings: builder.mutation<GetSettingsArgs, GetSettingsArgs >({
      query: ({ period, periodType }) => ({
        url: '/interval',
        method: 'PUT',
        body: {
          period,
          periodType
        }
      }),
      invalidatesTags: [{ type: 'Settings', id: 'SettingsLIST' }]
    }),
    getLeadIntervalSettings: builder.query({
      query: () => ({
        url: '/interval',
        method: 'GET'
      }),
      providesTags: [{ type: 'Settings', id: 'SettingsLIST' }]
    }),
    updateDeduplicationSettings: builder.mutation<DeduplicationSettingsArgs, DeduplicationSettingsArgs >({
      query: ({ intervalPeriod, intervalPeriodType, fields, checkAll }) => ({
        url: '/deduplication',
        method: 'PUT',
        body: {
          fields,
          checkAll,
          intervalPeriod,
          intervalPeriodType
        }
      }),
      invalidatesTags: [{ type: 'Settings', id: 'SettingsLIST' }]
    }),
    getDeduplicationSettings: builder.query({
      query: () => ({
        url: '/deduplication',
        method: 'GET'
      }),
      providesTags: [{ type: 'Settings', id: 'SettingsLIST' }]
    }),
    getTimezone: builder.query({
      query: () => ({
        url: '/timezone',
        method: 'GET'
      })
    }),
    getAutologinDomains: builder.query({
      query: () => ({
        url: '/getAutologinDomains',
        method: 'GET'
      })
    }),
    editTimezone: builder.mutation<ISettings, { timezone: string }>({
      query: ({ timezone }) => ({
        url: '/timezone',
        method: 'PUT',
        body: {
          timezone
        }
      }),
      invalidatesTags: [{ type: 'Settings', id: 'SettingsLIST' }]
    }),
    editAutoLogin: builder.mutation<ISettings, { autoLoginDomain: string }>({
      query: ({ autoLoginDomain }) => ({
        url: '',
        method: 'PATCH',
        body: {
          autoLoginDomain
        }
      }),
      invalidatesTags: [{ type: 'Settings', id: 'SettingsLIST' }]
    }),
    getAllSettings: builder.query({
      query: () => ({
        url: '',
        method: 'GET'
      })
    })
  })
})

export const {
  useEditLeadIntervalSettingsMutation,
  useUpdateDeduplicationSettingsMutation,
  useGetTimezoneQuery,
  useEditTimezoneMutation,
  useGetLeadIntervalSettingsQuery,
  useGetDeduplicationSettingsQuery,
  useEditAutoLoginMutation,
  useGetAllSettingsQuery,
  useGetAutologinDomainsQuery
} = settingsApi
