import React from 'react'
import { Menu, MenuItem } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'
import { routeRefs } from '@/routes/routesList'
import { useNavigate } from 'react-router-dom'
import api from '@/api'
import { useSelector } from 'react-redux'
import { selectConversionsQueryParams } from '@/store/queryParams'

interface Props {
  ids: string[]
}

const ConversionActions = ({ ids }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { headers } = useSelector(selectConversionsQueryParams)

  const navigate = useNavigate()

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onExportToCSV = async () => {
    await api.getCSV('conversions', ids, headers, 'Conversions')
    handleClose()
  }
  const onExportToDealsUpdater = () => {
    navigate(routeRefs.financeAndTools.dealsUpdater, { state: { ids: ids.join(',') } })
    handleClose()
  }

  const triggerStyles: React.CSSProperties = {
    padding: '0 8px',
    borderRadius: '12px',
    border: '1px solid black',
    height: '40px',
    alignItems: 'center',
    marginLeft: 'auto',
    background: 'white',
    cursor: 'pointer',
    justifySelf: 'flex-end'
  }

  return <>
  <FlexBox
        onClick={(e) => handleClick(e)}
        sx={triggerStyles}
    >
        Actions ({ids.length})
    </FlexBox>
    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
    >
        <MenuItem onClick={onExportToCSV}>Export to CSV</MenuItem>
        <MenuItem onClick={onExportToDealsUpdater}>Export to Deals Updater</MenuItem>
    </Menu>
  </>
}

export default ConversionActions
