import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'

import { Typography, useTheme } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import * as TextFieldDS from '@/designSystem/TextField'
import * as AutocompleteDS from '@/designSystem/Autocomplete'

import Form from '@/components/Form'
import IOSSwitch from '@/components/Form/Switch'
import TextField from '@/components/Form/TextField'
import { ToastContainer } from 'react-toastify'
import Popup from '@/designSystem/Popup'

import { type SubmitHandler, type UseFormReturn } from 'react-hook-form'
import ActionButtons from '@/designSystem/ActionButtons'

import Autocomplete from '@/components/Form/Autocomplete'
import { ERenderer, EType as ERotationType, type IRotationControl } from '@/components/Pages/TrafficDistribution/RotationControl/RotationControlTypes'
import Type from './Type'
import FlexBox from '@/designSystem/FlexBox'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Renderer from './Renderer'
import { countryList } from '@/constants'
import { type Option } from '@/designSystem/Autocomplete/types'
import AddIcon from '@/designSystem/Icons/Add'
import { nanoid } from '@reduxjs/toolkit'
import PQL from '@/designSystem/TreeView/PQL'
import { EType } from '@/designSystem/TreeView/types'
import AdvertiserSelection from './AdvertiserSelection'
import { findElementById } from '@/designSystem/TreeView/helpers'
import { useGetRotationsQuery } from '@/store/rotationControl'
import { selectRotationControlQueryParams } from '@/store/queryParams'
import { useSelector } from 'react-redux'
import GoalTypeSelection from './GoalTypeSelection'
import FormAutocomplete from '@/components/Form/FormAutocomplete'
import { LeadState } from '@/enums/leads.enum'

export interface Inputs {
  name: string
  description: string
  renderer: ERenderer
  nodeParentId: string | null
  active: boolean
  type: ERotationType
  pql: string
  status: string
  children: IRotationControl[]
  advertiserId: string
  ads: IAds[]
  capAmount: number
  goalType: Option<LeadState.FTD | LeadState.PUSHED_LEAD>
  revenue: number
  rejectedCap: number
  rejectedTimeoutCap: number
  resetCounterHours: number[]
  weight: number
  fillFirst: boolean
}
export const defaultRotationAdvertiser: IAds = {
  id: nanoid(),
  name: '',
  type: ERotationType.Advertiser,
  revenue: 100,
  goalType: LeadState.FTD,
  pql: '',
  fillFirst: false,
  status: 'ACTIVE',
  weight: 1,
  temp: true,
  capAmount: 1000,
  rejectedCap: 10,
  rejectedTimeoutCap: 1,
  advertiserId: undefined,
  resetCounterHours: []
}
export interface AddPopupProps {
  nodeParentId?: string | null
  form: UseFormReturn<Inputs>
  onSubmit: SubmitHandler<Inputs>
  handleClose: () => void
  title: string
  subtitle: string
  id?: number
  parentRotation?: IRotationControl
  isCreate: boolean
}

type IAds = Partial<Omit<IRotationControl & {
  touched?: boolean
  isOnDelete?: boolean
  temp?: boolean
}, 'id'>> & { id: string }

export const goalTypeOptions = [{
  title: 'PUSHED LEAD', value: 'PUSHED_LEAD'
}, {
  title: 'FTD', value: 'FTD'
}, {
  title: 'FTD LATE', value: 'FTD_LATE'
}]

const CreateAdvertisement = ({ onAdd }: { onAdd: () => void }) => {
  return <FlexBox sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
    <Typography variant='h1'>Advertiser</Typography>
    <AddIcon onClick={onAdd} />
  </FlexBox>
}

const timeCounterOptions = [{ title: '00:00 UTC', value: '0' },
  { title: '01:00 UTC', value: '1' }, { title: '02:00 UTC', value: '2' },
  { title: '03:00 UTC', value: '3' }, { title: '04:00 UTC', value: '4' },
  { title: '05:00 UTC', value: '5' }, { title: '06:00 UTC', value: '6' },
  { title: '07:00 UTC', value: '7' }, { title: '08:00 UTC', value: '8' },
  { title: '09:00 UTC', value: '9' }, { title: '10:00 UTC', value: '10' },
  { title: '11:00 UTC', value: '11' }, { title: '12:00 UTC', value: '12' },
  { title: '13:00 UTC', value: '13' }, { title: '14:00 UTC', value: '14' },
  { title: '15:00 UTC', value: '15' }, { title: '16:00 UTC', value: '16' },
  { title: '17:00 UTC', value: '17' }, { title: '18:00 UTC', value: '18' },
  { title: '19:00 UTC', value: '19' }, { title: '20:00 UTC', value: '20' },
  { title: '21:00 UTC', value: '21' }, { title: '22:00 UTC', value: '22' },
  { title: '23:00 UTC', value: '23' }]

interface AdvertiserNewProps {
  item: IAds
  onChange: (id: string, key: string, value: any) => void
  onDelete: (id: string) => void
  percent: number
  isFillFirstAvaliable: boolean
}

const AdvertiserNew = ({ item, onChange, onDelete, percent, isFillFirstAvaliable }: AdvertiserNewProps) => {
  const { spacing } = useTheme()

  const fillFirstOptions = [{
    title: 'Yes', value: true
  },
  {
    title: 'No', value: false
  }]

  const activeOptions = [{
    title: 'Yes', value: 'ACTIVE'
  }, {
    title: 'No', value: 'INACTIVE'
  }]

  return <FlexBox sx={{ flexDirection: 'column', gap: spacing(3), background: '#F1F3FF', padding: spacing(4), borderRadius: spacing(4) }}>
    <TextFieldDS.default
      required
      title="Name"
      value={item.name}
      onChange={(e: any) => onChange(item.id, 'name', e.target.value)}
    />
    <FlexBox sx={{ justifyContent: 'space-between', gap: spacing(5) }}>
      <AdvertiserSelection sx={{ flexGrow: 1 }} advertiserId={item.advertiserId} onChange={(option: Option<string> | null) => onChange(item.id, 'advertiserId', option?.value)} />
      {/* <AutocompleteDS.default
        title="Advertiser"
        sx={{ flexGrow: 1, '& .MuiOutlinedInput-root': { padding: '0px 4px 7.5px 5px' } }}
        onChange={(option: Option<string> | null) => onChange(item.id, 'advertiserId', option?.value)}
        onInputChange={(e) => setSearch(e.target.value || '')}
        defaultValue={{ title: item.name ?? '', value: item.advertiserId ?? '' }}
        options={advertisersOptions}
        aria-required={true}
      /> */}
      {!item.fillFirst && <TextFieldDS.default
        type="number"
        InputProps={{
          inputProps: { min: 1 },
          value: Number(item.weight)
        }}
        required
        title="Weight"
        value={item.weight}
        onChange={(e: any) => e.target.value ? onChange(item.id, 'weight', e.target.value) : onChange(item.id, 'weight', 0)}
      />}
    </FlexBox>
    <FlexBox sx={{ justifyContent: 'space-between', gap: spacing(5) }}>
      <AutocompleteDS.default
        sx={{ flex: '0 0 100px', '& .MuiOutlinedInput-root': { padding: '0px 4px 7.5px 5px' } }}
        title="Fill First"
        value={fillFirstOptions.find((i) => i.value === item.fillFirst)}
        onChange={(data: { title: string, value: boolean } | null) => data && onChange(item.id, 'fillFirst', data.value)}
        getOptionDisabled={(option) => option.value === item.fillFirst || (option.value && !isFillFirstAvaliable)}
        options={fillFirstOptions}
      />
      <AutocompleteDS.default
        sx={{ flex: '0 0 100px', '& .MuiOutlinedInput-root': { padding: '0px 4px 7.5px 5px' } }}
        title="Enabled"
        value={activeOptions.find((i) => i.value === item.status)}
        onChange={(data: { title: string, value: string } | null) => data && onChange(item.id, 'status', data.value)}
        options={activeOptions}
      />
      <FlexBox alignItems={'flex-end'}>
        {<Typography variant='h5' sx={{ alignItems: 'flex-end' }}>
          {!item.fillFirst ? `${(percent * 100).toFixed(2)}%` : ''}
        </Typography>}
      </FlexBox>
      <FlexBox alignCenter>
        <DeleteForeverIcon sx={{ fill: 'red' }} onClick={() => onDelete(item.id)} />
      </FlexBox>
    </FlexBox>
    <AutocompleteDS.default
      sx={{ '& .MuiOutlinedInput-root': { padding: '0px 4px 7.5px 5px' } }}
      title="Goal Type"
      onChange={(data: { title: string, value: string } | null) => data && onChange(item.id, 'goalType', data.value)}
      options={goalTypeOptions}
      value={goalTypeOptions.find((i) => i.value === item.goalType)}
    />
    <TextFieldDS.default
      type="number"
      InputProps={{
        inputProps: { min: 1 }
      }}
      required
      title="Revenue"
      value={item.revenue}
      onChange={(e: any) => onChange(item.id, 'revenue', +(e.target.value))}
    />
    <TextFieldDS.default
      type="number"
      InputProps={{
        inputProps: { min: 1 }
      }}
      required
      title="Cap amount"
      value={item.capAmount}
      onChange={(e: any) => onChange(item.id, 'capAmount', +(e.target.value))}
    />
    <TextFieldDS.default
      type="number"
      InputProps={{
        inputProps: { min: 1 }
      }}
      required
      title="Reject Amount"
      value={item.rejectedCap}
      onChange={(e: any) => onChange(item.id, 'rejectedCap', +(e.target.value))}
    />
    <TextFieldDS.default
      type="number"
      InputProps={{
        inputProps: { min: 1 }
      }}
      required
      title="Timeout Amount"
      value={item.rejectedTimeoutCap}
      onChange={(e: any) => onChange(item.id, 'rejectedTimeoutCap', +(e.target.value))}
    />
    <Box
      display={'flex'}
      flexDirection={'column'}
    >
      <Typography
        fontSize={18}
        fontWeight={400}
        marginBottom={'4.5px'}
      >Reset counter time</Typography>
      <FormAutocomplete
        name="resetCounterHours"
        rowSelectedOptions
        options={timeCounterOptions}
        selectedOptions={(item?.resetCounterHours && item.resetCounterHours.length > 0) ? item?.resetCounterHours : []}
        onChange={(option: Option<string> | null) => {
          if (option && item?.resetCounterHours) {
            const newValue = Number(option?.value)
            const isUnique = !item?.resetCounterHours.includes(newValue)

            if (isUnique) {
              onChange(item.id, 'resetCounterHours', [...item?.resetCounterHours, newValue])
            }
          } else if (option) {
            onChange(item.id, 'resetCounterHours', [Number(option?.value)])
          }
        }}
        deleteField={(value: string) => () => {
          onChange(item.id, 'resetCounterHours', item.resetCounterHours?.filter((item: number) => item !== Number(value)))
        }}
        placeholder="Choose Times"
        textFieldProps={{
          input: {
            sx: {
              input: {
                padding: '0px 0px 0px 10px !important'
              }
            }
          }
        }}
      />
    </Box>
  </FlexBox>
}

export default function AddOrEditPopup ({
  form,
  onSubmit,
  handleClose,
  title,
  subtitle,
  nodeParentId,
  id,
  parentRotation,
  isCreate
}: AddPopupProps) {
  const theme = useTheme()

  const [type, setType] = useState<Inputs['type'] | null>(form.getValues().type)
  const [renderer, setRenderer] = useState<ERenderer | null>(form.getValues().renderer)

  const hasFoldersInside = parentRotation?.children.some((i) => i.type === EType.RotationFolder) ?? false
  const hasAdvertisersInside = parentRotation?.children.some((i) => i.type === EType.Advertiser) ?? false

  const { filters } = useSelector(selectRotationControlQueryParams)
  const allRotations = useGetRotationsQuery({ filters }).data

  const parentId = parentRotation?.nodeParentId
  const parentNode = findElementById(allRotations, parentId)

  const existingAdvertisers = form.getValues().children
    ?.filter((item) => item.type === ERotationType.Advertiser)
    .map((item) => ({
      ...item,
      touched: false,
      isOnDelete: false
    })) ?? []
  const [advertisers, setAdvertisers] = useState<IAds[]>(existingAdvertisers)

  const [renderedAdvertisers, setRenderedAdvertisers] = useState<IAds[]>(advertisers)

  const countryOptions: Array<Option<string>> = Object.entries(countryList)
    .map(([key, value]) => ({
      title: value,
      value: key
    }))

  const onSetType = (value: Inputs['type'] | null) => {
    setType(value)
  }

  const onSetRenderer = (value: ERenderer | null) => {
    setRenderer(value)
  }

  useEffect(() => {
    setRenderedAdvertisers(advertisers.filter((item) => !item.isOnDelete))
    form.setValue('ads', advertisers)
  }, [advertisers])

  useEffect(() => {
    form.setValue('nodeParentId', nodeParentId ?? null)
  }, [])

  useEffect(() => {
    if (type) { form.setValue('type', type) }
  }, [type])

  useEffect(() => {
    if (renderer) { form.setValue('renderer', renderer) }
  }, [renderer])

  if (!type) {
    return <Popup
      open
      title={title}
      subTitle={subtitle}
      handleClose={handleClose}
      rootBoxSx={{ backgroundColor: '#F1F3FF' }}
    >
      <FlexBox sx={{ gap: theme.spacing(2) }} justifyCenter>
        {!hasFoldersInside && <Type type={ERotationType.Advertiser} onClick={onSetType} />}
        {!hasAdvertisersInside && <Type type={ERotationType.RotationFolder} onClick={onSetType} />}
      </FlexBox>
    </Popup>
  }

  if (!renderer) {
    return <Popup
      open
      title={title}
      subTitle={subtitle}
      handleClose={handleClose}
      rootBoxSx={{ backgroundColor: '#F1F3FF' }}
    >
      <Box sx={{ background: 'white', padding: theme.spacing(4), borderRadius: '16px' }}>
        <FlexBox sx={{ justifyContent: 'center', marginBottom: theme.spacing(3) }}>
          <ArrowBackIosIcon onClick={() => setType(null)} />
          <Box sx={{ width: 184 }}>
            <Type type={type} onClick={() => setType(null)} />
          </Box>
          <Box sx={{ width: 184 }}></Box>
        </FlexBox>
        <Typography variant='h2' sx={{ fontWeight: 'bold', marginBottom: theme.spacing(1) }}>Recommended</Typography>
        <Typography sx={{ marginBottom: theme.spacing(2) }}>Choose passing rule</Typography>
        <FlexBox sx={{ gap: theme.spacing(2) }}>
          <Renderer renderer={ERenderer.PassAll} onClick={onSetRenderer} />
          <Renderer renderer={ERenderer.Country} onClick={onSetRenderer} />
          <Renderer renderer={ERenderer.Custom} onClick={onSetRenderer} />
        </FlexBox>
      </Box>
    </Popup>
  }

  const newAdvertiser = { ...defaultRotationAdvertiser, pql: form.getValues('pql') }

  const addAdvertiser = () => setAdvertisers(prev => [...prev, newAdvertiser])
  const modifyAdvertiser = (id: string, key: string, value: any) => {
    const newAdvertisers = advertisers.map((item) => item.id === id
      ? {
          ...item,
          [key]: value,
          ...item?.touched === false ? { touched: true } : {}
        }
      : item)
    setAdvertisers(newAdvertisers)
  }

  const onDeleteAdvertiser = (id: string) => {
    const ads = advertisers.reduce((acc: IAds[], item) => {
      if (item.temp && item.id === id) {
        return acc
      }
      if (item.id === id) {
        return [
          ...acc,
          {
            ...item,
            isOnDelete: true
          }
        ]
      }
      return [...acc, item]
    }, [])
    setAdvertisers(ads)
  }

  const totalWeight = renderedAdvertisers.reduce((acc, item) => acc + (+((item.fillFirst ? 0 : item.weight) ?? 0)), 0)

  const onChange = (option: Option<string> | null) => {
    form.setValue('advertiserId', option?.value ?? '')
  }

  const onChangeGoalType = (option: Option<string> | null) => {
    form.setValue('goalType', option ?? {} as any)
  }

  return (
    <Popup
      open
      title={title}
      subTitle={subtitle}
      handleClose={handleClose}
      rootBoxSx={{ backgroundColor: '#F1F3FF' }}
    >
      <Box sx={{ background: 'white', padding: theme.spacing(4), borderRadius: '16px' }}>
        <FlexBox sx={{ justifyContent: 'center' }}>
          <ArrowBackIosIcon onClick={() => setRenderer(null)} />
          <Box sx={{ width: 184 }}>
            <Type type={type} />
          </Box>
          <Box sx={{ width: 184 }}>
            <Renderer renderer={renderer} onClick={() => setRenderer(null)} />
          </Box>
        </FlexBox>
        <Form form={form} onSubmit={onSubmit}>
          <Box
            display="flex"
            flexDirection="column"
            gap={theme.spacing(6)}
          >
            <Box display="flex" flexDirection="column" gap={theme.spacing(3)}>
              <TextField required title="Name" name="name" autoComplete='off' />
              {type === ERotationType.Advertiser && <>
                <AdvertiserSelection advertiserId={form.getValues('advertiserId')} onChange={onChange} />
                <GoalTypeSelection currentGoalType={form.getValues('goalType')} onChange={onChangeGoalType} />
                <TextField title="Revenue" name="revenue" type="number" required inputProps={{ min: 1 }} />
                <TextField title="Cap Amount" name="capAmount" type="number" required inputProps={{ min: 1 }} />
                <TextField title="Reject Amount" name="rejectedCap" type="number" inputProps={{ min: 1 }} />
                <TextField title="Timeout Amount" name="rejectedTimeoutCap" type="number" inputProps={{ min: 1 }} />
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Typography
                    color={theme.palette.text1.main}
                    fontSize={18}
                    fontWeight={400}
                    marginBottom={'4.5px'}
                  >Reset counter time</Typography>
                  <FormAutocomplete
                    name="resetCounterHours"
                    rowSelectedOptions
                    options={timeCounterOptions}
                    selectedOptions={form.watch('resetCounterHours')?.length > 0 ? form.watch('resetCounterHours') : []}
                    onChange={(option: Option<string> | null) => {
                      const currentValues = form.watch('resetCounterHours') || []
                      if (option) {
                        const newValue = Number(option?.value)
                        const isUnique = !currentValues.includes(newValue)

                        if (isUnique) {
                          form.setValue('resetCounterHours', [...currentValues, newValue])
                        } else if (option) {
                          form.setValue('resetCounterHours', [Number(option?.value)])
                        }
                      }
                    }}
                    deleteField={(value: string) => () => {
                      form.setValue('resetCounterHours', form.watch('resetCounterHours')?.filter((item: number) => item !== Number(value)))
                    }}
                    placeholder="Choose Times"
                    textFieldProps={{
                      input: {
                        sx: {
                          input: {
                            padding: '0px 0px 0px 10px !important'
                          }
                        }
                      }
                    }}
                  />
                </Box>
              </>}
              <TextField title="Description" name="description" autoComplete='off' />
              {renderer === ERenderer.Country && !(type === EType.Advertiser && parentNode?.renderer === ERenderer.Country) && <Autocomplete
                name="pql"
                title="Country"
                options={countryOptions}
                defaultValue={countryOptions.find((i) => {
                  const countryValue = form.getValues('pql')
                  if (typeof countryValue === 'string') {
                    const c = countryValue.replace('country IN [\'', '').replace('\']', '')
                    return i.value === c
                  }
                  return false
                })
                }
                value={countryOptions.find((i) => {
                  const countryValue = parentNode
                  if (typeof countryValue === 'string') {
                    const c = countryValue.replace('country IN [\'', '').replace('\']', '')
                    return i.value === c
                  }
                  return false
                })
                }
              />}
              {renderer === ERenderer.Custom && <PQL form={form} />}
            </Box>
            {(type === ERotationType.RotationFolder && isCreate) && <CreateAdvertisement onAdd={addAdvertiser} />}
            {renderedAdvertisers.map((item) =>
              <AdvertiserNew
                isFillFirstAvaliable={!renderedAdvertisers.find((i) => i.fillFirst)}
                key={item.id}
                item={item}
                onChange={modifyAdvertiser}
                onDelete={onDeleteAdvertiser}
                percent={((item.weight ?? 0) / totalWeight) || 0}
              />)}
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <IOSSwitch
                defaultValue
                formControlLabel={{
                  label: 'Active',
                  labelPlacement: 'start',
                  sx: {
                    margin: 0,
                    '& .MuiTypography-root': {
                      marginRight: theme.spacing(3)
                    }
                  }
                }}
                name="active"
              />
            </Box>
            <ActionButtons
              onCancel={handleClose}
            />
          </Box>
        </Form>

      </Box>
      <ToastContainer/>
    </Popup>
  )
}
