import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import {
  useGetConversionsQuery,
  setConversionsTotalCount,
  setConversions, selectConversions, selectConversionsTotalCount
} from '@/store/conversions'
import { Typography, useTheme } from '@mui/material'
import InjectPagination from './ConversionsPagination'
import InjectFilterBar from './Filters/ConversionsFilterBar'
import TableLayout from '@/designSystem/Table'
import elementConversionsMap from './ElementConversionsMap'
import { EmptyTableConversions } from './EmptyTableConversions'
import { type IConversions } from './ConversionsTypes'
import FlexBox from '@/designSystem/FlexBox'
import ConversionActions from './ConversionActions'
import { ModalEventInfo } from '@/designSystem/ModalMessagesLogs'
import { selectConversionsQueryParams } from '@/store/queryParams'

const Header = ({ header, isChecked, setChecked }: { header: string, isChecked: boolean, setChecked: (value: boolean) => void }) => {
  const onClick = () => {
    setChecked(!isChecked)
  }

  if (header.startsWith('::')) {
    return <input
      type='checkbox'
      style={{
        marginLeft: '18px',
        width: '24px',
        height: '24px'
      }}
      checked={isChecked}
      onClick={onClick}
    />
  }
  return (
    <Typography
      variant='body1'
      color="text1"
      width="max-content"
      sx={{
        display: 'flex'
      }}
    >
      {header}
    </Typography>
  )
}

export default function ConversionsTable () {
  const dispatch = useDispatch()
  const [isHeaderChecked, setHeaderChecked] = useState(false)
  const totalCount = useSelector(selectConversionsTotalCount)

  const [checkedIds, setCheckedIds] = useState<string[]>([])

  const { limit = 10, headers, filters, page, include = { lead: true } } = useSelector(selectConversionsQueryParams)
  console.log('include', include)
  const { data } = useGetConversionsQuery({ limit, filters, page, include })
  const allItemsIds = useGetConversionsQuery({ limit: totalCount, filters, page: 1 }).data?.conversions.map((i) => i.id)
  // const menu = useSelector(selectConversionsMenu)
  const { spacing } = useTheme()
  const [modalLogs, setModalLogs] = React.useState<any>(null)
  const conversions: IConversions[] = useSelector(selectConversions)
  const [conversionsState, setConversionsState] = React.useState<any>(conversions.map((i) => ({ ...i, isCheck: false })))
  const headersWithExtraFields = ['::indicatorConversions']?.concat(headers)
  const headerNodes: React.ReactNode[] =
    headersWithExtraFields?.map((header) => <Header key={header} header={header} isChecked={isHeaderChecked} setChecked={setHeaderChecked}/>)

  const handleCheck = (id: string) => {
    if (checkedIds.includes(id)) {
      setCheckedIds(checkedIds.filter((i) => i !== id))
    } else {
      setCheckedIds([...checkedIds, id])
    }
  }

  useEffect(() => {
    if (isHeaderChecked) {
      setCheckedIds(allItemsIds ?? [])
    } else {
      setCheckedIds([])
    }
  }, [isHeaderChecked])

  const rowNodes: any = conversionsState?.map((row: any) => (
    headersWithExtraFields?.map((header) =>
      elementConversionsMap(header, row, checkedIds.includes(row.id), handleCheck))
  ))

  // useEffect(() => {
  //   if (menu?.name === 'Late') {
  //     const activeConversions = conversions?.filter((conv: any) => conv?.isLate === true)
  //     setConversionsState(activeConversions)
  //   } else if (menu?.name === 'Not Late') {
  //     const finishedConversions = conversions?.filter((conv: any) => conv?.isLate === false)
  //     setConversionsState(finishedConversions)
  //   } else {
  //     setConversionsState(conversions)
  //   }
  // }, [menu])

  useEffect(() => {
    if (data) {
      dispatch(setConversions(data?.conversions ?? []))
      setConversionsState(data?.conversions ?? [])
      dispatch(setConversionsTotalCount(data?.totalCount ?? 0))
    }
  }, [data])

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={spacing(6)}
    >
      <FlexBox>
        <InjectPagination />
        {!!checkedIds.length && <ConversionActions ids={checkedIds} />}
      </FlexBox>
      <InjectFilterBar />
      <TableLayout
        headers={headerNodes}
        rows={rowNodes}
        sx={{
          '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-of-type': {
            width: 0
          }
        }}
      />
      {(rowNodes?.length === 0) && <EmptyTableConversions />}
      {modalLogs && <ModalEventInfo
        open={!!modalLogs}
        handleClose={() => setModalLogs(null)}
        logs={modalLogs}
      />}
    </Box>
  )
}
