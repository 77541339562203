import React, { useEffect, useState } from 'react'

import TableLayout from '@/designSystem/Table'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import fieldToElementMap from '@/designSystem/Pages/TrafficData/Hold/Table/fieldToElementMap'
import { useGetHoldsQuery, setTotalCount, type Hold, selectHolds, setHold, selectTotalCount } from '@/store/hold'
import { Typography, useTheme } from '@mui/material'
import Pagination from './Pagination'
import { EmptyTableLeads } from '../Leads/EmptyTableLeads'
import FilterBar from './Filters/HoldsFilterBar'
import FlexBox from '@/designSystem/FlexBox'
import HoldActions from './HoldActions'
import { ModalEventInfo } from '@/designSystem/ModalMessagesLogs'
import { useNavigate } from 'react-router-dom'
import { routeRefs } from '@/routes/routesList'
import { selectHoldQueryParams } from '@/store/queryParams'
import QuickSearchFilter from '@/components/Pages/TrafficData/Leads/Filters/QuickSearchFilterInput'

const Header = ({ header, isChecked, setChecked }: { header: string, isChecked: boolean, setChecked: (val: boolean) => void }) => {
  const onClick = () => {
    setChecked(!isChecked)
  }
  if (header === '::indicatorHolds') {
    return <input
      type='checkbox'
      style={{
        marginLeft: '18px',
        width: '24px',
        height: '24px'
      }}
      checked={isChecked}
      onClick={onClick}
    />
  }
  if (header === '::expand') {
    return <Box
      style={{
        marginLeft: '18px',
        width: '2px',
        height: '2px'
      }}
    />
  }
  return (
    <Typography
      variant='body1'
      color="text1"
      width="max-content"
      sx={{
        display: 'flex'
      }}
    >
      {header}
    </Typography>
  )
}

export enum CheckedIdType {
  NONE = 'none',
  HOLDS = 'holds',
  SENT_LEADS = 'sentLeads'
}

export default function Table () {
  const dispatch = useDispatch()
  const [isHeaderChecked, setHeaderChecked] = useState(false)
  const totalCount = useSelector(selectTotalCount)

  const [checkedIds, setCheckedIds] = useState<string[]>([])

  const handleOpenLead = (id: string) => {
    window.open(`/traffic-data/leads/${id}`, '_blank')
  }

  const { limit = 20, headers, filters, page } = useSelector(selectHoldQueryParams)
  const allItemsIds = useGetHoldsQuery({ limit: totalCount, filters, page: 1 }).data?.leads.reduce((acc: string[], i) => [...acc, i.id], [])
  const [checkedIdsType, setCheckedIdsType] = useState<CheckedIdType>(CheckedIdType.NONE)
  const { data } = useGetHoldsQuery({ limit, filters, page })
  const { spacing } = useTheme()
  const [modalLogs, setModalLogs] = React.useState<any>(null)
  const leadsState: Hold[] = useSelector(selectHolds)
  const [stateHolds, setStateHolds] = React.useState<any[]>(leadsState.map((i) => ({ ...i, isExpanded: false })))
  const headersWithExtraFields = ['::indicatorHolds', '::expand']?.concat(headers)

  useEffect(() => {
    if (isHeaderChecked) {
      setCheckedIds(allItemsIds ?? [])
      setCheckedIdsType(CheckedIdType.HOLDS)
    } else {
      setCheckedIds([])
      setCheckedIdsType(CheckedIdType.NONE)
    }
  }, [isHeaderChecked])

  const headerNodes: React.ReactNode[] =
    headersWithExtraFields?.map((header) => <Header key={header} header={header} isChecked={isHeaderChecked} setChecked={setHeaderChecked} />)

  const handleCheck = (id: string, idType: CheckedIdType) => {
    if (checkedIds.length === 0) {
      setCheckedIds([id])
      setCheckedIdsType(idType)
    }
    if (checkedIds.includes(id)) {
      setCheckedIds(checkedIds.filter((i) => i !== id))
      if (checkedIds.length === 0) setCheckedIdsType(CheckedIdType.NONE)
    } else {
      if (idType !== checkedIdsType) return
      setCheckedIds([...checkedIds, id])
    }
  }

  const handleExpand = (id: string) => {
    const tmp = stateHolds.map((i) => {
      if (i.id === id) {
        return {
          ...i,
          isExpanded: !i.isExpanded
        }
      }
      return i
    })
    setStateHolds(tmp)
  }

  // const rowNodes: any = stateLeads?.map((row: any) => (
  //   headersWithExtraFields?.map((header, i) => { return <div key={i} style={{ background: '#F1F3FF' }}>{elementLeadsMap(header, row, handleCheck)}</div> })
  // ))

  const rowNodes: any = stateHolds?.reduce((acc: any[], row: any) => {
    if (row.isExpanded) {
      const sentLeads = row.sentLeads.filter((i: any) => !i.excluded).map((i: any) => headersWithExtraFields?.map((header) => fieldToElementMap(header, { ...i, country: row.country }, checkedIds.includes(i.id), handleCheck, undefined, () => {}, handleOpenLead, true)))
      return [...acc, headersWithExtraFields?.map((header) => fieldToElementMap(header, row, checkedIds.includes(row.id), handleCheck, row.sentLeads?.filter((i: any) => !i.excluded).length > 0 ? row.isExpanded : undefined, handleExpand, handleOpenLead)), ...sentLeads]
    }
    return [...acc, headersWithExtraFields?.map((header) => fieldToElementMap(header, row, checkedIds.includes(row.id), handleCheck, row.sentLeads?.filter((i: any) => !i.excluded).length > 0 ? row.isExpanded : undefined, handleExpand, handleOpenLead))]
  }, [])

  useEffect(() => {
    if (data?.leads) {
      data && dispatch(setHold(data?.leads))
      setStateHolds(data?.leads.map((i, index) => ({ ...i, isExpanded: false })))
      dispatch(setTotalCount(data?.totalCount ?? 0))
    }
  }, [data])

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={spacing(6)}
    >
      <FlexBox justifyContent={'space-between'}>
        <QuickSearchFilter type={'holds'}/>
        <Pagination />
        {checkedIds.length > 0 && <HoldActions clearIds={() => setCheckedIds([])} ids={checkedIds} idsType={checkedIdsType} />}
      </FlexBox>
      <FilterBar />
      <TableLayout
        headers={headerNodes}
        rows={rowNodes}
        sx={{
          '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-of-type': {
            width: 0
          }
        }}
      />
      {(rowNodes?.length === 0) && <EmptyTableLeads />}
      {modalLogs && <ModalEventInfo
        open={!!modalLogs}
        handleClose={() => setModalLogs(null)}
        logs={modalLogs}
      />}
    </Box>
  )
}
