import { type Option } from '@/designSystem/Autocomplete/types'
import {
  getCurrentDay,
  getCurrentMonth,
  getCurrentWeek,
  getCurrentYear,
  getLastWeek
} from '@/components/Dashboard/utils'

export const getPeriodFilterOptions = (): Array<Option<string>> => [
  { title: 'This day', value: getCurrentDay().from },
  { title: 'This week', value: getCurrentWeek().from },
  { title: 'Last week', value: getLastWeek().from },
  { title: 'This month', value: getCurrentMonth().from },
  { title: 'This year', value: getCurrentYear().from }
]
const subtractDays = (startingDate: Date, daysCount: number) =>
  new Date(new Date().setDate(startingDate.getDate() - daysCount)).toISOString()
const subtractMonth = (startingDate: Date, monthsCount: number) =>
  new Date(new Date().setMonth(startingDate.getMonth() - monthsCount)).toISOString()
const subtractYears = (startingDate: Date, yearsCount: number) =>
  new Date(new Date().setFullYear(startingDate.getFullYear() - yearsCount)).toISOString()
