import React, { useEffect, useState } from 'react'
import { type FilterItemProps } from '.'
import { Typography, useTheme } from '@mui/material'
import Autocomplete from '@/designSystem/Autocomplete'
import { type Option } from '@/designSystem/Autocomplete/types'
import SelectedValues from './components/SelectedValues'
import { sx } from '../constants'

type Props = FilterItemProps & { options: Array<Option<string | boolean>>, setSearch?: (value: string) => void }

const AutocompleteFilterItem = (props: Props) => {
  const { title, selectedValues, name, onChange, isMultiple, options, setSearch, placement } = props
  const { spacing, palette } = useTheme()
  const [value, setValue] = useState(!isMultiple ? options.find((i) => i.value === selectedValues) : null)
  const [search, setLocalSearch] = useState(value?.title ?? '')

  const onSave = (option: Option<string | boolean> | null) => {
    if (option) {
      setValue(option)
      isMultiple ? setLocalSearch('') : setLocalSearch(option.title)
      onChange(name as any, option.value, isMultiple)
    }
  }

  useEffect(() => {
    setLocalSearch(value?.title ?? '')
  }, [])

  return (
  <>
    {placement !== 'quickSearch' &&
     <Typography variant="body1" sx={{ marginTop: spacing(4) }} color={palette.card.main}>{title}</Typography>
    }
    <Autocomplete
        getOptionDisabled={(option) => !!(Array.isArray(selectedValues) && selectedValues[0] && typeof selectedValues[0] === 'string' && selectedValues.includes(option.value as any))}
        sx={sx}
        options={options}
        onChange={(option) => onSave(option)}
        value={!isMultiple ? options.find((i) => i.value === selectedValues) : null}
        inputValue={search}
        onInputChange={(e) => { setLocalSearch(e.target.value || ''); setSearch && setSearch(e.target.value) }}
    />
    {placement !== 'quickSearch' &&
     <SelectedValues {...props} />
    }
  </>)
}
export default AutocompleteFilterItem
