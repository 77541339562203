import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import { format } from 'date-fns'

import type { Option } from '@/designSystem/Autocomplete/types'
import setProperty from '@/utils/setProperty'
import FilterIcon from '@/designSystem/Pages/Advertisers/Icons/Filter'
import SettingsIcon from '@/assets/icons/Settings'
import { Box, useTheme } from '@mui/material'
import Button from '@/designSystem/Button'
import IconButton from '@/designSystem/IconButton'
import Autocomplete from '@/designSystem/Pages/Advertisers/Autocomplete'
import TermsFiltersPopup from './Filters/TermsFiltersPopup'
import TermsSettingsPopup from './Filters/TermsSettingsPopup'
import { useGetTermsQuery } from '@/store/terms'
import isObjectNotEmpty from '@/utils/isObjectNotEmpty'
import { selectTermsQueryParams, setTermsQueryParams } from '@/store/queryParams'
import { getPeriodFilterOptions } from '@/utils/filters'
import { getLastWeek } from '@/components/Dashboard/utils'

export default function RightActionButtons () {
  const theme = useTheme()
  const [isOpenFilterPopup, setIsOpenFilterPopup] = useState(false)
  const [isSettingsOpen, setIsSettingOpen] = useState(false)

  const [name, setName] = useState<string | null>(null)
  const [debouncedName] = useDebounce(name, 450)

  const dispatch = useDispatch()
  const queryParams = useSelector(selectTermsQueryParams)

  const isFilter = queryParams?.filters && isObjectNotEmpty(queryParams?.filters)

  const onChangeDate = (option: Option<string> | null) => {
    return option?.value && dispatch(setTermsQueryParams({
      ...queryParams,
      page: 1,
      filters: setProperty({ ...queryParams?.filters }, 'createdTo', {
        from: new Date(option?.value).toISOString(),
        to: option?.title === 'Last week' ? getLastWeek().to : new Date().toISOString()
      })
    }))
  }
  const onInputChangeDate = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value
    if (!value) {
      dispatch(setTermsQueryParams({
        ...queryParams,
        page: 1,
        filters: setProperty({ ...queryParams?.filters }, 'createdTo')
      }))
    }
  }

  const nameOptions: any = useGetTermsQuery({ limit: 6, filters: debouncedName ? { label: debouncedName } : undefined })
    .data?.terms?.map((term) => ({ title: term?.label, value: term?.label }))

  const onInputChangeName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value
    setName(value ?? null)
    if (!value) {
      dispatch(setTermsQueryParams({
        ...queryParams,
        filters: setProperty({ ...queryParams?.filters }, 'label')
      }))
    } else {
      dispatch(setTermsQueryParams({
        ...queryParams,
        filters: setProperty({ ...queryParams?.filters }, 'label', debouncedName)
      }))
    }
  }
  const onChangeName = (value: Option<string> | null) => {
    setName(value?.value ?? null)
    return value && dispatch(
      setTermsQueryParams({
        ...queryParams,
        page: 1,
        filters: setProperty({ ...queryParams?.filters }, 'label', value?.value)
      })
    )
  }

  useEffect(() => {
    if (!queryParams?.filters?.label) {
      setName(null)
    }
  }, [queryParams?.filters?.label])

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={theme.spacing(4)}
      >
        {isFilter && <Button
          onClick={() => dispatch(setTermsQueryParams({ ...queryParams, page: 1, filters: undefined }))}
          text='Reset filter'
          variant='contained'
          sx={{
            backgroundColor: theme.palette.red2.main,
            padding: `${theme.spacing(2)} ${theme.spacing(6)}`
          }}
        />}
        <IconButton onClick={() => setIsOpenFilterPopup(true)}>
          <FilterIcon />
        </IconButton>
        <Box
          display="flex"
          alignItems="center"
          gap={theme.spacing(3)}
        >
          <Autocomplete
            onInputChange={onInputChangeName}
            onChange={onChangeName}
            value={{ value: name ?? '', title: name ?? '' }}
            clearIcon=''
            options={nameOptions ?? []}
            placeholder='Label'
            leftShift
          />
          <Autocomplete
            onInputChange={onInputChangeDate}
            onChange={onChangeDate}
            options={getPeriodFilterOptions()}
            clearIcon=''
            placeholder='Select data'
            leftShift
          />
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => setIsSettingOpen(true)}
          >
            <SettingsIcon />
          </IconButton>
        </Box>
      </Box>
      {isSettingsOpen && (<TermsSettingsPopup handleClose={() => setIsSettingOpen(false)} />)}
      {isOpenFilterPopup && (
        <TermsFiltersPopup handleClose={() => setIsOpenFilterPopup(false)} />
      )}
    </>
  )
}
