import React, { useState, useEffect, type ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, MenuItem, Select, type SelectChangeEvent, useTheme } from '@mui/material'
import {
  selectHoldQueryParams,
  selectLeadsQueryParams,
  setHoldQueryParams,
  setLeadsQueryParams
} from '@/store/queryParams'
import composeSx from '@/designSystem/TextField/v2'
import { type Option } from '@/designSystem/Autocomplete/types'
import FilterItem from '@/components/Pages/TrafficData/Hold/Filters/FilterItem'
import { type LeadsFilters } from '@/store/leads'
import { type FilterItemType } from '@/components/Pages/TrafficData/Leads/Filters/constants'
import isObjectNotEmpty from '@/utils/isObjectNotEmpty'
import * as AutocompleteDS from '@/designSystem/Autocomplete'
import ActionsSelect from '@/designSystem/ActionsSelect'
import { PullLeadActionType } from '@/enums/advertiser.enum'

const quickSearchFilters: FilterItemType[] = [
  {
    title: 'UUIDS',
    name: 'id',
    isMultiple: true
  },
  {
    title: 'Advertiser',
    name: 'advertiserId',
    isMultiple: true
  },
  {
    title: 'Country',
    name: 'country',
    isMultiple: true
  },
  {
    title: 'State',
    name: 'state',
    isMultiple: true
  },
  {
    title: 'Email',
    name: 'email',
    isMultiple: false
  }
]
const quickSearchFilterOptions: Array<Option<string>> = quickSearchFilters.map(item => ({ value: item.name, title: item.title }))

export default function QuickSearchFilter ({ type }: { type: 'holds' | 'leads' }) {
  const dispatch = useDispatch()
  const queryParams = useSelector(type === 'leads' ? selectLeadsQueryParams : selectHoldQueryParams)
  const theme = useTheme()
  const [selectedFilter, setSelectedFilter] = useState<FilterItemType>(quickSearchFilters[0])
  const textFieldsSx = composeSx(theme)

  const options = type === 'leads' ? quickSearchFilterOptions : quickSearchFilterOptions.filter(item => item.value !== 'state')

  const [filters, setFilters] = useState<LeadsFilters>(queryParams.filters ?? {})

  useEffect(() => {
    if (isObjectNotEmpty(filters)) {
      const data = {
        ...queryParams,
        page: 1,
        filters
      }
      dispatch(type === 'leads' ? setLeadsQueryParams(data) : setHoldQueryParams(data))
    }
  }, [filters])

  useEffect(() => {
    if (!queryParams.filters || !isObjectNotEmpty(queryParams.filters)) {
      setFilters({})
    }
  }, [queryParams])

  const onDelete = (field: keyof LeadsFilters, value: any, isMultiselect: boolean) => {
    if (!isMultiselect) return
    const filtersValue = filters[field]
    if (filtersValue && Array.isArray(filtersValue) && filtersValue[0] !== undefined && typeof filtersValue[0] === 'string' && filtersValue?.includes(value)) { // delete if value is the same
      const newFilters = {
        ...filters,
        [field]: (filters[field] as any[]).filter((i) => i !== value)
      }
      setFilters(newFilters)
    }
  }
  const onChangeFilterType = (option: Option<string> | null) => {
    const filter = quickSearchFilters.find(item => item.name === option?.value)
    setSelectedFilter(filter ?? quickSearchFilters[0])
  }

  const onChangeFilters = (field: keyof LeadsFilters, value: any, isMultiselect: boolean) => {
    if (value === undefined || value === null || (typeof value === 'string' && value.length === 0)) return
    if (isMultiselect) {
      const newFilters = {
        // ...filters,
      //  page: 1,
        [field]: Array.isArray(filters[field]) ? (filters[field] as any[]).concat(value) : [value]
      }
      setFilters(newFilters)
    } else {
      const newFilters = {
        // ...filters,
        [field]: value
      }
      setFilters(newFilters)
    }
  }
  return (
    <Box display="flex" sx={{ marginRight: theme.spacing(6) }} alignItems="flex-start" gap={theme.spacing(3)}>
      <AutocompleteDS.default
        plaseholder="Filter"
        sx={{ width: 230 }}
        onChange={onChangeFilterType}
         textFieldProps={{
           root: {
             sx: textFieldsSx
           }
         }}
        options={options}
        value={options.find((i) => i.value === selectedFilter?.name)}
      />

      <FilterItem
        onDelete={onDelete}
        key={selectedFilter.name}
        title={selectedFilter.title}
        name={selectedFilter.name}
        onChange={onChangeFilters}
        isMultiple={selectedFilter.isMultiple}
        placement={'quickSearch'}
        selectedValues={filters[selectedFilter.name]}
      />
     </Box>
  )
}
