import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import FilterIcon from '@/designSystem/Pages/Advertisers/Icons/Filter'
import SettingsIcon from '@/assets/icons/Settings'
import { Box, useTheme } from '@mui/material'
import Button from '@/designSystem/Button'
import IconButton from '@/designSystem/IconButton'
import HoldsFiltersPopup from './Filters/HoldsFiltersPopup'
import isObjectNotEmpty from '@/utils/isObjectNotEmpty'
import { selectHoldQueryParams, setHoldQueryParams } from '@/store/queryParams'
import { type Option } from '@/designSystem/Autocomplete/types'
import Autocomplete from '@/designSystem/Pages/Advertisers/Autocomplete'
import HoldsSettingsPopup from '@/components/Pages/TrafficData/Hold/Filters/HoldsSettingsPopup'
import { getPeriodFilterOptions } from '@/utils/filters'
import { getLastWeek } from '@/components/Dashboard/utils'

export default function RightActionButtons () {
  const theme = useTheme()
  const [isOpenFilterPopup, setIsOpenFilterPopup] = useState(false)
  const [isSettingsOpen, setIsSettingOpen] = useState(false)

  const dispatch = useDispatch()
  const queryParams = useSelector(selectHoldQueryParams)

  const isFilter = queryParams?.filters && isObjectNotEmpty(queryParams?.filters)

  const onDateChange = (option: Option<string> | null) => {
    return option?.value && dispatch(setHoldQueryParams({
      ...queryParams,
      page: 1,
      filters: {
        ...queryParams.filters,
        createdAt: {
          from: new Date(option?.value).toISOString(),
          to: option?.title === 'Last week' ? getLastWeek().to : new Date().toISOString()
        }
      }
    }))
  }
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={theme.spacing(4)}
      >
        {isFilter && <Button
          onClick={() => dispatch(setHoldQueryParams({ ...queryParams, page: 1, filters: undefined }))}
          text='Reset filter'
          variant='contained'
          sx={{
            backgroundColor: theme.palette.red2.main,
            padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
            width: 'max-content'
          }}
        />}
        <Autocomplete
          sx={{
            width: '200px', cursor: 'pointer'
          }}
          onChange={onDateChange}
           options={getPeriodFilterOptions()}
          clearIcon=''
          placeholder='Date'
        />
        <IconButton onClick={() => setIsOpenFilterPopup(true)}>
          <FilterIcon />
        </IconButton>
        <Box
          display="flex"
          alignItems="center"
          gap={theme.spacing(3)}
        >
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => setIsSettingOpen(true)}
          >
            <SettingsIcon />
          </IconButton>
        </Box>
      </Box>
      {isSettingsOpen && (<HoldsSettingsPopup handleClose={() => setIsSettingOpen(false)} />)}
      {isOpenFilterPopup && (
        <HoldsFiltersPopup handleClose={() => setIsOpenFilterPopup(false)} />
      )}
    </>
  )
}
