import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import MuiTextField from '@mui/material/TextField'
import ClickToLead from '../Checkbox/Icons/ClickToLead'
import ConversionRate from '../Checkbox/Icons/ConversionRate'
import PayloadSum from '../Checkbox/Icons/PayloadSum'
import LeadPayuot from '../Checkbox/Icons/LeadPayuot'
import LeadRevenue from '../Checkbox/Icons/LeadRevenue'
import PushLeads from '../Checkbox/Icons/PushLeads'
import EarningPerClick from '../Checkbox/Icons/EarningPerClick'
import PartnerEPC from '../Checkbox/Icons/PartnerEPC'
import Pnl from '../Checkbox/Icons/Pnl'
import Roi from '../Checkbox/Icons/Roi'
import TotalFtd from '../Checkbox/Icons/TotalFtd'
import Epl from '../Checkbox/Icons/Epl'
import EplCPA from '../Checkbox/Icons/EplCPA'
import EplCPL from '../Checkbox/Icons/EplCPL'
import ManualFTD from '../Checkbox/Icons/ManualFTD'
import FtdFromManual from '../Checkbox/Icons/FtdFromManual'
import RealFDT from '../Checkbox/Icons/RealFDT'
import RealCR from '../Checkbox/Icons/RealCR'
import RejectedLeads from '../Checkbox/Icons/RejectedLeads'
import FlexBox from '../FlexBox'

export const iconData = [
  { value: 'Click To lead', title: 'Click To lead', icon: <ClickToLead /> },
  { value: 'CR', title: 'CR', icon: <ConversionRate /> },
  { value: 'FTD Payout', title: 'FTD Payout', icon: <PayloadSum /> },
  { value: 'Lead Payout', title: 'Lead Payout', icon: <LeadPayuot /> },
  { value: 'Lead Revenue', title: 'Lead Revenue', icon: <LeadRevenue /> },
  { value: 'Leads', title: 'Leads', icon: <PushLeads /> },
  { value: 'OG-EPC', title: 'OG-EPC', icon: <EarningPerClick /> },
  { value: 'Partner EPC', title: 'Partner EPC', icon: <PartnerEPC /> },
  { value: 'PNL', title: 'PNL', icon: <Pnl /> },
  { value: 'ROl', title: 'ROl', icon: <Roi /> },
  { value: 'Total FTD', title: 'Total FTD', icon: <TotalFtd /> },
  { value: 'EPL', title: 'EPL', icon: <Epl /> },
  { value: 'EPL CPL', title: 'EPL CPL', icon: <EplCPL /> },
  { value: 'EPL CPA', title: 'EPL CPA', icon: <EplCPA /> },
  { value: 'Manual FTDs', title: 'Manual FTDs', icon: <ManualFTD /> },
  { value: 'FTDs from manual', title: 'FTDs from manual', icon: <FtdFromManual /> },
  { value: 'Real FTDs', title: 'Real FTDs', icon: <RealFDT /> },
  { value: 'Real CR', title: 'Real CR', icon: <RealCR /> },
  { value: 'Rejected Leads', title: 'Rejected Leads', icon: <RejectedLeads /> }
]

export const iconOption = [
  { value: 'Click To lead', title: 'Click To lead' },
  { value: 'CR', title: 'CR' },
  { value: 'FTD Payout', title: 'FTD Payout' },
  { value: 'Lead Payout', title: 'Lead Payout' },
  { value: 'Lead Revenue', title: 'Lead Revenue' },
  { value: 'Leads', title: 'Leads' },
  { value: 'OG-EPC', title: 'OG-EPC' },
  { value: 'Partner EPC', title: 'Partner EPC' },
  { value: 'PNL', title: 'PNL' },
  { value: 'ROl', title: 'ROl' },
  { value: 'Total FTD', title: 'Total FTD' },
  { value: 'EPL', title: 'EPL' },
  { value: 'EPL CPL', title: 'EPL CPL' },
  { value: 'EPL CPA', title: 'EPL CPA' },
  { value: 'Manual FTDs', title: 'Manual FTDs' },
  { value: 'FTDs from manual', title: 'FTDs from manual' },
  { value: 'Real FTDs', title: 'Real FTDs' },
  { value: 'Real CR', title: 'Real CR' },
  { value: 'Rejected Leads', title: 'Rejected Leads' }
]

interface ISelectIconTagField {
  form: any
}

export const SelectIconTagField = ({ form }: ISelectIconTagField) => {
  const { spacing, palette } = useTheme()
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  const handleChoise = (value: string) => {
    form.setValue('icon', value)
    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
    >
      <Typography
        variant='body2'
        sx={{
          marginBottom: spacing(1.125)
        }}
        color={palette.text2.main}
      >
        Icon
      </Typography>
      <MuiTextField
        onClick={handleClick}
        autoComplete="off"
        value={form.getValues('icon')}
        sx={{
          '& .MuiInputBase-root': {
            height: '40px',
            borderRadius: '16px'
          },
          '& .MuiOutlinedInput-root:hover': {
            '& > fieldset': {
              borderColor: palette.primary.main
            }
          },
          '& .Mui-disabled:hover': {
            '& > fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.38)'
            }
          },
          '& fieldset': { borderColor: palette.primary.main }
        }}
      />
      {open && <FlexBox
        column
        padding={'10px'}
        marginTop={'20px'}
        sx={{
          backgroundColor: palette.leftBar.main,
          border: `1px solid ${palette.primary.main}`,
          borderRadius: '16px'
        }}
      >
        <FlexBox
          columnGap={'5px'}
          flexWrap="wrap"
        >
          {iconData.map((item) => (
            <Box
              onClick={() => handleChoise(item.value)}
              key={item.value}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
              }}
            >
              {item.icon}
            </Box>
          ))}
        </FlexBox>
      </FlexBox>}
    </Box>
  )
}
