import { PullLeadSettingsType } from '@/enums/advertiser.enum'

export const checkRequestTimeout = (input: string | undefined): string => {
  if (!input) {
    return ''
  }
  if (isNaN(Number(input)) || Number(input) <= 0) {
    return 'Request timeout not valid. Please enter a number greater than 0.'
  }
  if (Number(input) > 300000) {
    return 'Request timeout not valid.Please enter a number that is less than 120000.'
  }
  return ''
}
export const pullSettingsTypeOptions = [{
  title: 'Pull lead statuses', value: PullLeadSettingsType.PULL_LEAD_STATUSES
}, {
  title: 'Pull lead states', value: PullLeadSettingsType.PULL_LEAD_STATES
}]
export const defaultTimeout = '3000'
