import React from 'react'
import { Menu, MenuItem } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'
import { useDeleteHoldMutation } from '@/store/hold'
import { routeRefs } from '@/routes/routesList'
import { useNavigate } from 'react-router-dom'
import api from '@/api'
import { useSelector } from 'react-redux'
import { CheckedIdType } from './Table'
import { usePatchLeadMutation } from '@/store/leads'
import { selectHoldQueryParams } from '@/store/queryParams'

interface Props {
  ids: string[]
  idsType: CheckedIdType
  clearIds: () => void
}

const HoldActions = ({ ids, idsType, clearIds }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { headers } = useSelector(selectHoldQueryParams)
  const [deleteHold] = useDeleteHoldMutation()
  const [patchLead] = usePatchLeadMutation()

  const navigate = useNavigate()

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onExportToCSV = async () => {
    await api.getCSV('leads', ids, headers, 'Hold')
    handleClose()
  }

  const onExportToInjections = () => {
    navigate(routeRefs.injections.injections, { state: { uuids: ids.join(',') } })
    handleClose()
  }

  const onDelete = async () => {
    ids.forEach(async (id) => {
      await deleteHold({ id, idsType })
    })
    clearIds()
    handleClose()
  }

  const handleUpdateTestToTrue = async () => {
    ids.forEach(async (id) => {
      await patchLead({ id, data: { isTest: true } })
    })
    clearIds()
    handleClose()
  }

  const handleUpdateTestToFalse = async () => {
    ids.forEach(async (id) => {
      await patchLead({ id, data: { isTest: false } })
    })
    clearIds()
    handleClose()
  }

  const triggerStyles: React.CSSProperties = {
    padding: '0 8px',
    borderRadius: '12px',
    border: '1px solid black',
    height: '40px',
    alignItems: 'center',
    marginLeft: 'auto',
    background: 'white',
    cursor: 'pointer',
    justifySelf: 'flex-end'
  }

  return <>
    <FlexBox
      onClick={(e) => handleClick(e)}
      sx={triggerStyles}
    >
      Actions ({ids.length})
    </FlexBox>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
    >
      {idsType === CheckedIdType.HOLDS && <>
        <MenuItem onClick={onExportToCSV}>Export to CSV</MenuItem>
        <MenuItem onClick={onExportToInjections}>Export to Injections</MenuItem>
         <MenuItem onClick={handleUpdateTestToTrue}>Update Test status to true</MenuItem>
        <MenuItem onClick={handleUpdateTestToFalse}>Update Test status to false</MenuItem>
      </>}
      <MenuItem onClick={onDelete}>{idsType === CheckedIdType.HOLDS ? 'Remove' : 'Exclude'}</MenuItem>
    </Menu>
  </>
}

export default HoldActions
