import React from 'react'
import Box from '@mui/material/Box'

import { useTheme } from '@mui/material'

import Form from '@/components/Form'
import TextField from '@/components/Form/TextField'

import Popup from '@/designSystem/Popup'

import { type SubmitHandler, type UseFormReturn } from 'react-hook-form'
import ActionButtons from '@/designSystem/ActionButtons'

import { type ERenderer, type EType } from '@/components/Pages/LeadConversions/AdvertiserRevenue/AdvertiserRevenueTypes'

export interface Inputs {
  name: string
  renderer: ERenderer
  nodeParentId: string | null
  active: boolean
  type: EType
  pql: string
  status: string
  description: string
  revenueValue: number
}

export interface AddPopupProps {
  nodeParentId: string | null
  form: UseFormReturn<Inputs>
  onSubmit: SubmitHandler<Inputs>
  handleClose: () => void
  title: string
  subtitle: string
  id?: number
}

export default function AddOrEditPopup ({
  form,
  onSubmit,
  handleClose,
  title,
  subtitle
}: AddPopupProps) {
  const theme = useTheme()

  return (
    <Popup
      open
      title={title}
      subTitle={subtitle}
      handleClose={handleClose}
      rootBoxSx={{ backgroundColor: '#F1F3FF' }}
    >
      <Box sx={{ background: 'white', padding: theme.spacing(4), borderRadius: '16px' }}>
        <Form form={form} onSubmit={onSubmit}>
          <Box
            display="flex"
            flexDirection="column"
            gap={theme.spacing(6)}
          >
            <Box display="flex" flexDirection="column" gap={theme.spacing(3)}>
              <TextField title="Name" name="name" disabled />
              <TextField title='Revenue' name='revenueValue' type='number' />
            </Box>
            <ActionButtons
              onCancel={handleClose}
            />
          </Box>
        </Form>

      </Box>
    </Popup>
  )
}
